/******************
 * Non-async action types
 */
import { Loadable } from '../..';

export const USER_ROOT = 'user';
export const USER_LOGOUT = 'USER__USER_LOGOUT';
export const SET_AUTH_TYPE = 'SET_AUTH_TYPE';
export const SET_LANGUAGE_OVERRIDE = 'USER_SET_LANGUAGE_OVERRIDE';
export const SET_USER_DATA = 'USER__SET_USER_DATA';

type Guid = string;

/**
 * JWT token obtained through the normal login procedure
 */
export interface AccessTokenPayload {
    iss: string; // "https://qa.api.biw.cloud/v1/id",
    integrity: string;
    iat: number;
    amr: string; // "pwd",
    aud: string; // "arcade-bros",
    cmp: Guid;
    sub: Guid;
    jti: string; // JWT ID,
    scope: string; // "openid profile company permissions email recovery",
    token_type: 'access_token';
    person_id?: Guid; // Roster person ID
    external_id?: Guid; // ID of a person in a non-Roster system, e.g. Daymaker <=6.4
    permissions: {};
    nbf: number;
    exp: number;
}

/**
 * JWT token obtained through a s2s call, e.g. Daymaker <=6.4 embedding the widget
 */
export interface ClientCredentialsPayload {
    iss: string; // "https://qa.api.biw.cloud/v1/id",
    integrity: string;
    iat: number;
    amr: string; // "pwd",
    aud: string; // "arcade-bros",
    cmp: Guid;
    jti: string; // JWT ID,
    token_type: 'client_credentials';
    person_id?: Guid; // Roster person ID
    external_id?: Guid; // ID of a person in a non-Roster system, e.g. Daymaker <=6.4
    permissions: {};
    nbf: number;
    exp: number;
}

export interface UserDataClaims {
    sub: Guid;
    sid: Guid;
    s_hash: string;
    name: string;
    family_name: string;
    given_name: string;
    initials: string;
    nickname: string;
    preferred_username: string;
    person_id: Guid;
    external_id: string;
    locale: string;
    country: string;
    attributes: {
        status: 'active';
    };
    company_id: Guid;
    company_name: string; // 'Super Arcade Bros'
    company_url: string; // 'https://qa.api.biw.cloud/v1/company-setup'
    permissions: {};
    email: { [key: string]: string };
    recovery_methods_set: true;
    recovery_methods_url: string; // 'https://arcade-bros.qa.door.biw.cloud/recovery-info'
}

export interface AuthState {
    access_token: string;
    claims: UserDataClaims;
    expires_at: number;
    id_token: string;
}

export interface SessionState {
    access_token?: string;
    accessTokenPayload?: AccessTokenPayload | ClientCredentialsPayload;
    authState?: AuthState;
    locale: Loadable<string>;
}

export interface SessionAppState {
    session: SessionState;
}
