import React from 'react';
import { TimePicker as AntTimePicker } from 'antd';
import customIcons from '../date-picker/custom-icons';

const { clearIcon, clockIcon, exchangeIcon } = customIcons;

const TimePicker = ( { ...props } ) => (
    <AntTimePicker
        suffixIcon={ clockIcon }
        allowClear={ { clearIcon: clearIcon } }
        { ...props } />
);

const RangePicker = ( { ...props } ) => (
    <AntTimePicker.RangePicker
        suffixIcon={ clockIcon }
        allowClear={ { clearIcon: clearIcon } }
        separator={ exchangeIcon }
        { ...props }
    />
);

TimePicker.RangePicker = RangePicker;

export { TimePicker };