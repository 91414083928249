import pathToRegexp from 'path-to-regexp';

const normalize = ( path ) => {
    path = ( path || '' );

    if ( path[ 0 ] === '/' ) {
        path = path.substring( 1 );
    } 

    return '/' + path;
};

export default ( pattern ) => {
    pattern = normalize( pattern );

    const keys = [];
    const regex = pathToRegexp( pattern, keys );
    const paramNames = keys.map( ( key ) => key.name );

    return ( pathname ) => {
        pathname = normalize( pathname );
        const match = regex.exec( pathname );

        if ( !match ) {
            return false;
        } else {
            const result = {};

            match.splice( 1 ).forEach( ( paramValue, index ) => {
                result[ paramNames[ index ] ] = paramValue;
            } );

            return result;
        }
    };
};
