import mapValues from 'lodash/mapValues';
import { applyMiddleware, combineReducers, compose, createStore, Store } from 'redux';
import thunk from 'redux-thunk';
import { games, GamesAppState } from './games';
import { links, LinksAppState } from './links';
import { play, PlayAppState } from './play';
import { preferences, PreferencesAppState } from './preferences';
import { promotion, PromotionAppState } from './promotion';
import { session, SessionAppState } from './session';
import { tokens, TokensAppState } from './tokens';
import { translations, TranslationsAppState } from './translations';

// Combine partial application states defined by each module.
export type AppState =
    GamesAppState &
    LinksAppState &
    PlayAppState &
    PreferencesAppState &
    PromotionAppState &
    SessionAppState &
    TokensAppState &
    TranslationsAppState;

export const reducers = {
    games,
    links,
    play,
    preferences,
    promotion,
    session,
    tokens,
    translations,
};

const DEFAULT_STATE = mapValues(reducers, (reducer, key) => {
    if (!reducer) {
        throw new Error(`No reducer exported for ${key}`);
    }
    if (!reducer.defaultState) {
        console.warn(`no default state found for reducer: ${key} - please attach a 'defaultState' property to the exported reducer for the store to use`);
    }
    return reducer.defaultState;
});

const appReducer = combineReducers(reducers);

const createAppStore = (): Store<AppState> => {
    // Redux devtools only run if the extension is present in the browser
    // https://chrome.google.com/webstore/detail/redux-devtools/lmhkpmbekcpmknklioeibfkpmmfibljd?hl=en
    // @ts-ignore ignore errors from redux devtools
    const composeFunc = typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ ?
        /* istanbul ignore next */
        // @ts-ignore ignore errors from redux devtools
        window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
            actionsBlacklist: ['dataFetched', 'dataFetching'],
            maxAge: 100,
        }) : compose;

    const enhancers = [applyMiddleware(thunk)];

    return createStore(
        appReducer, // ( s: any, action: AnyAction ) => s,
        DEFAULT_STATE,
        composeFunc(...enhancers),
    );
};

export { createAppStore };
