import jwt_decode from 'jwt-decode';
import { DispatchFor } from '../../util/createReducer';
import { session } from './reducer';
import { AccessTokenPayload, AuthState, ClientCredentialsPayload, SET_USER_DATA, USER_LOGOUT } from './types';

export type SessionDispatch = DispatchFor<typeof session>;

export const loadTokenString = (tokenString: string) => async (dispatch: SessionDispatch) => {
    const accessTokenPayload = jwt_decode<object>(tokenString) as AccessTokenPayload | ClientCredentialsPayload;

    if (!accessTokenPayload.person_id && !accessTokenPayload.external_id) {
        console.warn('Access token does not contain an external_id field. Service calls will fail.');
    }

    return dispatch({
        type: 'SET_ACCESS_TOKEN',
        payload: {
            access_token: tokenString,
            accessTokenPayload,
        },
    });
};

export const setLocale = (locale: string) => async (dispatch: SessionDispatch) => {
    return dispatch({
        type: 'SET_LOCALE',
        payload: locale,
    });
};

export function setUserData(authState: AuthState | null) {
    return {
        type: SET_USER_DATA,
        payload: {
            access_token: authState ? authState.access_token : undefined,
            authState,
            accessTokenPayload: authState ? jwt_decode<object>(authState.access_token) as AccessTokenPayload : undefined,
        },
    };
}

export function userLogout() {
    return {
        type: USER_LOGOUT,
    };
}
