import { createReducer } from '../../util/createReducer';
import { errorLoading, loaded, loading, notLoaded } from '../../util/Loadable';
import { LinksState } from './types';

const initialState: LinksState = {
    arcadeUrl: notLoaded(),
};

export const links = createReducer(initialState, {
    ARCADE_URL_LOADING: (state: LinksState) => ({
        ...state,
        arcadeUrl: loading(state.arcadeUrl),
    }),

    ARCADE_URL_ERROR: (state: LinksState, error: any) => ({
        ...state,
        arcadeUrl: errorLoading(error, state.arcadeUrl),
    }),

    ARCADE_URL_LOADED: (state: LinksState, payload: string | null) => ({
        ...state,
        arcadeUrl: loaded(payload),
    }),
});
