import React from 'react';
import { DatePicker as AntDatePicker } from 'antd';
import customIcons from './custom-icons';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';

dayjs.extend( customParseFormat );
dayjs.extend( advancedFormat );
dayjs.extend( weekday );
dayjs.extend( localeData );
dayjs.extend( weekOfYear );
dayjs.extend( weekYear );
require( 'dayjs/locale/de' );
require( 'dayjs/locale/fr' );
require( 'dayjs/locale/fr-ca' );
require( 'dayjs/locale/nl' );
require( 'dayjs/locale/pt-br' );
require( 'dayjs/locale/th' );
require( 'dayjs/locale/ru' );
require( 'dayjs/locale/zh-cn' );
require( 'dayjs/locale/zh-tw' );
require( 'dayjs/locale/ja' );
require( 'dayjs/locale/es' );
require( 'dayjs/locale/es-mx' );
require( 'dayjs/locale/en-gb' );
require( 'dayjs/locale/pl' );
require( 'dayjs/locale/it' );
require( 'dayjs/locale/ko' );
require( 'dayjs/locale/tr' );
dayjs.extend( localizedFormat );

const {
    calendarIcon,
    clearIcon,
    exchangeIcon,
    nextIcon,
    prevIcon,
    superNextIcon,
    superPrevIcon
} = customIcons;

const DatePicker = ( { ...props } ) => (
    <AntDatePicker
        suffixIcon={ calendarIcon }
        allowClear={ { clearIcon: clearIcon } }
        nextIcon={ nextIcon }
        prevIcon={ prevIcon }
        superNextIcon={ superNextIcon }
        superPrevIcon={ superPrevIcon }
        { ...props }
    />
);

const RangePicker = ( { ...props } ) => (
    <AntDatePicker.RangePicker
        suffixIcon={ calendarIcon }
        allowClear={ { clearIcon: clearIcon } }
        separator={ exchangeIcon }
        nextIcon={ nextIcon }
        prevIcon={ prevIcon }
        superNextIcon={ superNextIcon }
        superPrevIcon={ superPrevIcon }
        { ...props }
    />
);

DatePicker.RangePicker = RangePicker;

export { DatePicker, dayjs };
