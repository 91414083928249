// Map "Java" types to Typescript types because I mostly copied these directly from the
// Arcade-API source code and if I need to re-copy them in the future it'll be easier
// if they don't shift.
/* tslint:disable:ban-types */
type UUID = string;
// type int = number;
type String = string;
type Integer = number | null;
type List<T> = T[];
type LocalDateTime = string | null; // ISO-8601 string without time zone

export interface ArcadeView {
    id: UUID;
    companyId: UUID;
    name: string;
    mainRoomId: UUID;
    autoplayPreferenceEnabled?: boolean;
    hideInstantPlays?: boolean;
    hideArcadeFloorLink?: boolean;
    bannerImageUrl?: string | null;
    arcadeLinkBackgroundImageUrl?: string | null;
    arcadeLinkForegroundImageUrl?: string | null;
    noInstantPlayBackgroundImageUrl?: string | null;
    noInstantPlayForegroundImageUrl?: string | null;
}

export interface ArcadesView {
    arcades: ArcadeView[];
}

interface ROMView {
    romId: UUID;
    name: String;
    description: String;
    metaData: String;
    resource: String;
    image: String;
    bannerImage: String;
}

export interface CabinetView extends ROMView {
    cabinetId: UUID;
    arcadeId: UUID;
    metaDataValues: String;
    image: String;
    bannerImage: String;
    instantPlayForegroundImageUrl?: String | null;
    instantPlayBackgroundImageUrl?: String | null;
}

export interface CabinetEntryView {
    cabinetId: UUID;
    name: String;
}

export interface RoomView {
    id: UUID;
    name: String;
    freePlay: boolean;
    cabinets: CabinetEntryView[];
}

export interface RoomsView {
    rooms: RoomView[];
}

export interface CabinetsView {
    cabinets: CabinetView[];
}

// This is declared inside token-service, but not mirrored in arcade-api
export enum TokenState {
    AVAILABLE = 'available',
    USED = 'used',
}

export interface Token {
    id: UUID;
    personId: UUID;
    roomId: UUID;
    state: TokenState | String;
}

export interface TokensView {
    tokens: Token[];
}

export enum CabinetHistoryStatusEnum {
    COMPLETE = 'COMPLETE',
    IN_PROGRESS = 'IN_PROGRESS',
}

export interface CabinetHistoryView  {
    // Primary Key of the Game History Object
    id: UUID;

    // Company Id
    companyId: UUID;

    // Arcade Id Associated with history
    arcadeId: UUID;

    // Person Id Associated with history
    personId: UUID;

    // Cabinet Id Associated with history
    cabinetId: UUID;

    // Game History Status
    status: CabinetHistoryStatusEnum;

    // Start time for playing
    startTime: LocalDateTime;

    // Complete time for playing
    completeTime: LocalDateTime;
}

export interface Reward {
    type: String;
    value: String;
}

export interface RedemptionView {
    tokens: Token[];
    rewards: List<Reward>;
}

export interface PlayGameRequest {
    score: Integer;
    personId: UUID;
    tokens: List<UUID>;
    cabinetId: UUID;
}

export interface PlayGameView {
    cabinetHistory: CabinetHistoryView;
    redemption: RedemptionView;
}

export interface CompletePlayGameRequest {
    isComplete: boolean;
    score: Integer;
}
