import React from 'react';

export const defaultState = {
    signedIn: false,
    requiresAuth: null
};

export const BridgeContext = React.createContext( defaultState );
BridgeContext.displayName = 'Bridge';

export default BridgeContext;
