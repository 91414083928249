import { GameplayUpdateOptions } from '@nackle/game-loader-comm-api';
import LoaderComponent, { ImageUrls } from '@nackle/game-loader-component';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import { AppState, FALLBACK_LOCALE, GameplayInProgress, OpenGame, Play, Session } from '../../dux';
import { ENV } from '../../util/apiConfig';
import { Loadable } from '../../util/Loadable';
import { ReduxProps } from '../../util/reactReduxExt';

interface Props {
    imageUrls: Partial<ImageUrls>;
    loaderUrl: string;
}

declare global {
    interface Window {
        arcadeEnvironment: string
    }
}

const buildLoaderComponentArgs = () => createSelector(
    [Play.getOpenCabinet, Play.getCurrentGameplay, Session.getSessionId, Session.getLocale],
    (openGame: OpenGame | undefined, currentGameplay: GameplayInProgress | undefined, sessionId: string | undefined, locale: Loadable<string>) => {
        if (!openGame || !sessionId) {
            return undefined;
        }

        const { cabinetId, config, instructions, name, openedAt, romId } = openGame;

        return {
            cabinetId,
            config,
            instructions,
            localeCode: locale.isLoaded ? locale.data : FALLBACK_LOCALE,
            openedAt,
            romId,
            romName: name,
            getSession: () => sessionId,
        };
    },
);

const mapStateToProps = () => {
    const getLoaderComponentArgs = buildLoaderComponentArgs();

    return (state: AppState) => {
        return {
            openCabinet: Play.getOpenCabinet(state),
            loaderComponentArgs: getLoaderComponentArgs(state),
            playAgainTokenIds: Play.getOpenGameTokenIds(state),
        };
    };
};

const mapDispatchToProps = {
    closeGame: Play.closeGame,
    completeGame: Play.completeGame,
    playGame: Play.playGame,
    updateScore: Play.updateScore,
};

/**
 * Bridge between Redux state and the game loader
 */
class GameLoaderController extends React.Component<ReduxProps<Props, typeof mapStateToProps, typeof mapDispatchToProps>> {

    public state = {
        errorMessage: [],
    };

    public render() {
        const { imageUrls, loaderComponentArgs, loaderUrl } = this.props;

        if (!loaderComponentArgs) {
            return <></>;
        }

        const environment = window.arcadeEnvironment ? window.arcadeEnvironment : ENV;

        return (
            <LoaderComponent
                {...loaderComponentArgs}
                env={environment}
                loaderUrl={loaderUrl}
                onClose={this.props.closeGame}
                onPlay={this.props.playAgainTokenIds && this.handlePlayAgain}
                gameplayUpdate={this.handleGameplayUpdate}
                imageUrls={imageUrls}
            />
        );
    }

    private handleGameplayUpdate = (options: GameplayUpdateOptions) => {
        const { complete, score = null } = options;
        if (complete) {
            this.props.completeGame(score);
        } else {
            this.props.updateScore(score);
        }
    }

    private handlePlayAgain = async () => {
        try {
            const {
                openCabinet,
                playAgainTokenIds,
                playGame,
            } = this.props;

            if (!openCabinet) {
                throw new Error('Cannot play: No cabinet is open');
            }
            if (!playAgainTokenIds) {
                throw new Error('Cannot play: No tokens to play with');
            }

            return await playGame(playAgainTokenIds);
        } catch (e) {
            console.error(e);
            throw e;
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameLoaderController);
