import React from 'react';
import routeHistory from './route-history.js';

class Route extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            route: {
                id: 0,
                pathname: '',
                search: '',
                query: {},
                match: null,
                props: {},
                component: null
            }
        };
    }

     componentDidMount() {
        routeHistory.addEventListener( 'changing', this.handleChanging );
        routeHistory.addEventListener( 'changed', this.handleChanged );
        // force a change event to be fired in case this was mounted after
        // the route change
        routeHistory.refresh( true );
    }

    componentWillUnmount() {
        routeHistory.removeEventListener( 'changing', this.handleChanging );
        routeHistory.removeEventListener( 'changed', this.handleChanged );
    }

    render() {
        if ( typeof this.props.render === 'function' ) {
            return this.props.render( this.state.route );
        } else {
            return null;
        }
    }

    handleChanging = () => {
        if ( typeof this.props.onChangeStarted === 'function' ) {
            this.props.onChangeStarted();
        }
    }

    handleChanged = ( action, route, prevRoute ) => {
        if ( action !== 'none' ) {
            let component = null;
            if ( route && route.match && typeof route.match.render === 'function' ) {
                // pull out the parameters from the state
                const { params, ...state } = route.state;

                component = route.match.render( {
                    ...route.state.props,
                    ...{
                        route: {
                            params: params,
                            query: route.query,
                            hash: route.hash,
                            state: state
                        }
                    }
                } );
            }

            this.setState( {
                route: Object.assign( {}, route, { component } )
            } );
        }

        if ( typeof this.props.onChangeEnded === 'function' ) {
            this.props.onChangeEnded( action, route, prevRoute );
        }
    }
}

export default Route;
