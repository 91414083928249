import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { InputNumber as AntInputNumber } from 'antd';
import { MathPlus, MathMinus } from '@nackle/origami-icons';
import classNames from 'classnames';
const InputNumber = forwardRef( ( props, ref ) => {
    const { type, className, ...restProps } = props;
    const inputClass = classNames( {
        [ className ]: className ? true : false,
        'inline': type === 'inline' ? true : false,
        'default': type === 'inline' ? false : true
    } );
    return (
        <AntInputNumber
            ref={ ref }
            className={ inputClass }
            controls={ type === 'inline' ? { upIcon: <MathPlus />, downIcon: <MathMinus /> } : true }
            { ...restProps }
        />
    );
} );

InputNumber.propTypes = {
    /** display incrementers inside input (default) or to either side (inline) */
    type: PropTypes.oneOf( [ 'inline', 'default' ] ),
    /** optional class name for input */
    className: PropTypes.string
};

export { InputNumber };