/* tslint:disable:max-classes-per-file */

import { Arcade } from '../../api';
import { CabinetView, Games, GamesAppState } from '../games';
import { CabinetContentType } from '../games/cmxKeys';
import { Session, SessionAppState } from '../session';
import { Tokens, TokensAppState, TokensDispatch } from '../tokens';
import { TranslationsAppState } from '../translations';
import { PlayDispatch } from './reducer';
import { getCurrentGameplay, getOpenCabinet } from './selectors';
import { GameplayInProgress, OpenGame, PlayAppState } from './types';

type PartialAppState = GamesAppState & PlayAppState & SessionAppState & TokensAppState;

export class GameLifecycleError extends Error {}

const parseGameConfig = ({ cabinetId, metaDataValues }: CabinetView): object => {
    let config: unknown;

    try {
        config = JSON.parse(metaDataValues);
    } catch (e) {
        console.warn(`Cannot parse metadata configuration for cabinet ${cabinetId} (${metaDataValues})`, e);
        return {};
    }

    if (typeof config !== 'object' || config === null) {
        console.warn(`Configuration for cabinet ${cabinetId} (${metaDataValues}) is not an object`);
        return {};
    }

    return config;
};

export const openGame = (cabinetId: string, roomId: string) =>
    async (dispatch: TokensDispatch & PlayDispatch, getState: () => PartialAppState & TranslationsAppState) => {
        const state = getState();
        const openedAt = Date.now();

        // Gather information about the game that we've already downloaded
        const cabinet = Games.getCabinetById(state, cabinetId);
        if (!cabinet) {
            throw new Error(`No cabinet found with ID ${cabinetId}`);
        }

        const description = Games.getCabinetContent(state, CabinetContentType.DESCRIPTION, cabinetId, cabinet.romId, cabinet.description);
        const instructions = Games.getCabinetContent(state, CabinetContentType.INSTRUCTIONS, cabinetId, cabinet.romId, undefined);
        const name = Games.getCabinetContent(state, CabinetContentType.NAME, cabinetId, cabinet.romId, cabinet.name);

        const config = parseGameConfig(cabinet);

        const payload: OpenGame = {
            cabinetId,
            config,
            description,
            instructions,
            name,
            openedAt,
            romId: cabinet.romId,
            roomId,
        };

        dispatch({ type: 'PLAY_OPENED', payload });
    };

export const playGame = (tokenIds: string[]) =>
    async (dispatch: TokensDispatch & PlayDispatch, getState: () => PartialAppState & TranslationsAppState) => {
        const state = getState();
        const client = Session.getAxios(state);
        const personId = Session.getPersonId(state);
        const currentGame = getOpenCabinet(state);

        if (!currentGame) {
            throw new Error(`No game is currently open`);
        }

        // Tell the API we're starting to play
        const response = await Arcade.playGame(client, { personId, cabinetId: currentGame.cabinetId, tokenIds });
        const cabinetHistoryId = response.cabinetHistory.id;
        const rewards = response.redemption.rewards;
        const tokens = response.redemption.tokens;

        // We just "spent" tokens, so the server has marked them as such.
        await dispatch(Tokens.updateTokens(tokens));

        const payload: GameplayInProgress = {
            cabinetHistoryId,
            complete: false,
            rewards,
            score: null,
        };

        dispatch({ type: 'PLAY_STARTED', payload });

        return { rewards };
    };

export const updateScore = (score: number | null) =>
    async (dispatch: PlayDispatch, getState: () => PartialAppState) => {
        const state = getState();

        const currentlyPlaying = getCurrentGameplay(state);
        if (!currentlyPlaying) {
            throw new GameLifecycleError(`Can't update game: Game is not running`);
        }

        const { complete } = currentlyPlaying;
        if (complete) {
            throw new GameLifecycleError(`Can't update game: Game is already completed`);
        }

        dispatch({
            type: 'PLAY_UPDATED',
            payload: { score },
        });
    };

export const completeGame = (score: number | null) =>
    async (dispatch: PlayDispatch, getState: () => PartialAppState) => {
        const state = getState();
        const client = Session.getAxios(state);

        const currentlyPlaying = getCurrentGameplay(state);
        if (!currentlyPlaying) {
            throw new GameLifecycleError(`Can't complete game: Game is not running`);
        }

        const { cabinetHistoryId, complete } = currentlyPlaying;
        if (complete) {
            throw new GameLifecycleError(`Can't complete game: Game is already completed`);
        }

        await Arcade.playGameComplete(client, { cabinetHistoryId, isComplete: true, score });

        dispatch({
            type: 'PLAY_COMPLETED',
            payload: { score },
        });
    };

export const closeGame = () =>
    async (dispatch: PlayDispatch, getState: () => PartialAppState) => {
        const state = getState();

        const currentlyPlaying = getOpenCabinet(state);
        if (!currentlyPlaying) {
            // Can't end the game if it's already ended, but since it's idempotent we can fail silently
            return;
        }

        // TODO: Assert that game is completed?

        dispatch({ type: 'PLAY_CLOSED' });
    };
