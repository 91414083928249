/* A simple templating method based on Douglas Crockford's supplant. */
/* This method replaces all instances of the ${key} pattern with the */
/* corresponding key's value on the object passed into it. it also uses */
/* the pattern [key: text ${key} text] to signify repetition */
/* patterns. The inner text of the pattern is repeated N times, where N */
/* is the number of items at the key. A zero length array, null, */
/* undefined or empty string on the key removes the pattern entirely. */
/* Otherwise an array containing strings or objects gets applied */
/* recursively to the inner text pattern. */
/* https://gist.github.com/Breton/2497986 */

if ( !String.prototype.template ) {
  String.prototype.template = function ( o = {} ) {
      const keyRE = /\$\{([^}]*)\}/g;

      function replaceKeys( match, key ) {
          const r = o[ key ];
          return typeof r === 'string' ? r :
              typeof r === 'number' ? r.toString( 10 ) :
                  match; // '';
      }

      return this.replace( keyRE, replaceKeys );
  };
}

export {};
