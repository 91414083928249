// libs
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { CURRENT_LOCALE_CODE, languageChangeEvent } from '../translations';
import formatTime from './format-time.js';

const propTypes = {
    value: PropTypes.oneOfType( [
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf( Date )
    ] ),
    TextComponent: PropTypes.any
};

class FormattedTime extends Component {

    componentDidMount() {
        languageChangeEvent.on( this.onLanguageChange );
    }

    componentWillUnmount() {
        languageChangeEvent.off( this.onLanguageChange );
    }

    onLanguageChange = () => this.forceUpdate();

    render() {
        const {
            value,
            TextComponent = 'span',
            ...props
        } = this.props;

        const locale = CURRENT_LOCALE_CODE;

        return ( <TextComponent { ...props }>{ value && formatTime( locale, value ) }</TextComponent> );
    }
}

// @ts-ignore
FormattedTime.propTypes = propTypes;

export default FormattedTime;
