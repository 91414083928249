import { createReducer } from '../../util/createReducer';
import { TranslationsState } from './types';

export const translations = createReducer({
    translations: {},
} as TranslationsState, {
    TRANSLATIONS_LOADED: (state: TranslationsState, newTranslations: { [key: string]: string }) => ({
        ...state,
        translations: {
            ...state.translations,
            ...newTranslations,
        },
    }),
});
