import React from 'react';
import PropTypes from 'prop-types';
import { ColorPicker } from 'antd';
import { CirclePicker } from 'react-color';
import './color-gradient-picker.less';
import './color-swatches-picker.less';

const NackleColorPicker = ( props ) => {

    const swatchColors = [
        '#F5F7FA',
        '#B1BACA',
        '#9099A9',
        '#59606D',
        '#34383F',
        '#000000',
        '#787C04',
        '#759825',
        '#0AA648',
        '#198E48',
        '#239393',
        '#08827A',
        '#2A7A8D',
        '#48759C',
        '#3272D9',
        '#2C41E3',
        '#4949DB',
        '#6767E6',
        '#7A68B3',
        '#9656D6',
        '#BD56D6',
        '#D656CC',
        '#D6568C',
        '#D6246E',
        '#DE1B1B',
        '#EA3B12',
        '#EB6311',
        '#E68E00',
        '#D8C430',
        '#B77100'
    ];

    const handleChangeComplete = ( color ) => {
        if ( typeof props.onChangeComplete === 'function' ) {
            props.onChangeComplete( color );
        }
    };

    return ( 
            <>
                { props.type === 'swatches' &&
                    <div className='nackle-swatches-color-picker'>
                        <CirclePicker
                            colors={ swatchColors }
                            onChangeComplete={ ( color ) => handleChangeComplete( color ) }
                            color={ props.color ? props.color : undefined }
                            width={ 292 }
                            circleSpacing={ 0 }
                            circleSize={ 42 }
                        />
                    </div>
                }
                { props.type === 'gradient' &&
                    <div className='nackle-gradient-color-picker'>
                        <ColorPicker
                            onChangeComplete={ ( color ) => handleChangeComplete( color.metaColor ) }
                            color={ props.color ? props.color : undefined }
                            open={ true }
                            children={ <div /> }
                            getPopupContainer={ ( triggerNode ) => triggerNode.parentNode }
                            arrow={ false }
                        />
                    </div> 
                }
            </>
    );
};

NackleColorPicker.propTypes = {
    /** Type of color picker */
    type: PropTypes.oneOf( [ 'swatches', 'gradient' ] ).isRequired,
    /** Current selected color */
    color: PropTypes.string,
    /** callback returning color when color is picked*/
    onChangeComplete: PropTypes.func
};

export { NackleColorPicker };