import { AppState, Games, Play, Promotion, ReduxProps, Translate } from '@nackle/arcade-core';
import { PlayCircle } from '@nackle/icons/dist/react-es/antd-filled';
import { Button } from '@nackle/paper';
import Hero from 'components/hero';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { FALLBACK_ARCADE_IMAGE } from '../../assets';
import { GameBannerText, FloorText } from '../../translation-data';
import { GameBannerContent } from './GameBannerContent';
import './GameBanner.less';

const mapStateToProps = (state: AppState) => {
    const arcade = state.games.arcadeList.isLoaded && state.games.arcadeList.data[0];
    const maybePromotedGame = Promotion.getPromotedGame(state);
    const playsAvailableCount = maybePromotedGame.isLoaded && maybePromotedGame.data &&
        Play.countCabinetPlaysAvailable(state, maybePromotedGame.data.cabinetId, maybePromotedGame.data.roomId) || 0;
    const playTokenIds = maybePromotedGame.isLoaded && maybePromotedGame.data &&
        Play.getTokensIdsToPlay(state, maybePromotedGame.data.cabinetId, maybePromotedGame.data.roomId);

    return {
        arcadeBannerImage: arcade && arcade.bannerImageUrl || FALLBACK_ARCADE_IMAGE,
        maybePromotedGame,
        playsAvailableCount,
        playTokenIds,
    };
};

const mapDispatchToProps = {
    openGame: Play.openGame,
};

class GameBanner extends Component<ReduxProps<{}, typeof mapStateToProps, typeof mapDispatchToProps>> {
    public render() {
        const {
            arcadeBannerImage,
            maybePromotedGame,
            playsAvailableCount,
        } = this.props;

        if (!maybePromotedGame.isLoaded) {
            return <Hero />;
        }

        const promotedGame = maybePromotedGame.data;

        if (!promotedGame) {
            return (
                <Hero backgroundImageUrl={arcadeBannerImage}>
                    <GameBannerContent
                        header={<Translate
                            id={GameBannerText.FALLBACK_PROMOTION_TITLE}
                            fallback="Check back soon"
                        />}
                        description={<Translate id={GameBannerText.FALLBACK_PROMOTION_DESCRIPTION} fallback="For future gameplay opportunities" />}
                    />
                </Hero>
            );
        }

        return (
            <Hero backgroundImageUrl={promotedGame.bannerImage}>
                <GameBannerContent
                    header={
                        <Translate
                            fallback={promotedGame.fallbackName}
                            id={Games.cmxKeys.formatCmxRomNameKey(promotedGame.romId)}
                        >
                            {(fallbackName: string) => (
                                <Translate
                                    fallback={fallbackName}
                                    id={Games.cmxKeys.formatCmxCabinetNameKey(promotedGame.cabinetId)}
                                    stripHtml
                                />
                            )}
                        </Translate>
                    }
                    description={
                        <Translate
                            fallback={promotedGame.fallbackDescription}
                            id={Games.cmxKeys.formatCmxRomDescKey(promotedGame.romId)}
                        >
                            {(fallbackDescription: string) => (
                                <Translate
                                    fallback={fallbackDescription}
                                    id={Games.cmxKeys.formatCmxCabinetDescKey(promotedGame.cabinetId)}
                                    stripHtml
                                />
                            )}
                        </Translate>
                    }
                    playButton={
                        <Button
                            className="game-banner-play-button"
                            disabled={!playsAvailableCount}
                            icon={<PlayCircle/>}
                            id="play-now-button"
                            type="primary"
                            size="large"
                            onClick={this.handleClick}
                        >
                            <span></span>
                            <Translate
                                fallback="Play Now"
                                id={FloorText.PLAY_NOW_BUTTON_LABEL}
                            />
                        </Button>
                    }
                    preHeader={<Translate
                        args={{
                            playCount: playsAvailableCount,
                            playCountClass: 'game-banner-play-count',
                        }}
                        id={FloorText.PLAY_COUNT_LABEL}
                        parseHtml
                    />}
                />
            </Hero>
        );
    }

    private handleClick = async () => {
        try {
            const { maybePromotedGame, openGame, playTokenIds } = this.props;

            if (!maybePromotedGame.isLoaded || !playTokenIds) {
                return;
            }

            const promotedGame = maybePromotedGame.data;

            if (!promotedGame) {
                return;
            }

            await openGame(promotedGame.cabinetId, promotedGame.roomId);
        } catch (e) {
            console.error(e);
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(GameBanner);
