import { CabinetView, Games, Play, PreloadImage, ReduxProps, Translate } from '@nackle/arcade-core';
import { Spin } from '@nackle/paper';
import React from 'react';
import { connect } from 'react-redux';
import { FloorText } from '../../translation-data';

import './InstantPlay.less';

const GameImageBackground = ({ url }: { url: string }) => (
    <div
        className="instant-play-cover-image-background"
        style={{
            backgroundImage: `url(${url})`,
        }}
    />
);

const GameImageForeground = ({ url }: { url: string }) => (
    <img
        alt=""
        className="instant-play-cover-image-foreground"
        src={url}
    />
);

interface Props {
    cabinet: CabinetView;
    onClick: (roomId: string, cabinetId: string) => void;
    playCount: number;
    roomId: string;
}

interface State {
    foregroundLoaded?: boolean;
    backgroundLoaded?: boolean;
}

const mapDispatchToProps = {
    openGame: Play.openGame,
};

class InstantPlay extends React.Component<ReduxProps<Props, null, typeof mapDispatchToProps>, State> {
    public state: State = {};

    public render() {
        const {
            cabinet,
            playCount,
            roomId,
        } = this.props;

        const {
            foregroundLoaded,
            backgroundLoaded,
        } = this.state;

        const gameSlug = cabinet.name.replace(/\W+/g, '_').toLocaleLowerCase();

        const foregroundImageUrl = cabinet.instantPlayForegroundImageUrl;
        const backgroundImageUrl = cabinet.instantPlayBackgroundImageUrl
            || cabinet.image
            || cabinet.bannerImage;

        const imagesLoaded = (foregroundLoaded || !foregroundImageUrl) && backgroundLoaded;

        return (
            <Translate
                fallback={cabinet.name}
                id={Games.cmxKeys.formatCmxRomNameKey(cabinet.cabinetId)}
                stripHtml
            >
                {(gameName: string) => (
                    <Translate
                        id={FloorText.INSTANT_PLAY_GAME_LABEL}
                        args={{
                            gameName,
                            playCount,
                        }}
                    >
                        {(label: string) =>
                            <button
                                className={`nkl-card nkl-card-hoverable instantPlay-container`}
                                data-cabinet-id={cabinet.cabinetId}
                                data-rom-id={cabinet.romId}
                                data-room-id={roomId}
                                id="game-name-link"
                                onClick={this.handleClick}
                                aria-label={label}
                                title={label}
                            >
                                {foregroundImageUrl &&
                                    (
                                        <PreloadImage
                                            url={foregroundImageUrl}
                                            onPreloadComplete={this.handleForegroundPreloaded}
                                        />
                                    )
                                }
                                <PreloadImage
                                    url={backgroundImageUrl}
                                    onPreloadComplete={this.handleBackgroundPreloaded}
                                />

                                {!imagesLoaded && <Spin className="instant-play-cover-image-spinner" />}
                                {imagesLoaded && <GameImageBackground url={backgroundImageUrl} />}
                                {imagesLoaded && foregroundImageUrl && <GameImageForeground url={foregroundImageUrl} />}

                                <div className="instantPlay-plays" id={`instant_play_${gameSlug}_play_count`}>
                                    <Translate
                                        args={{ playCount, playCountClass: 'instantPlay-lozenge' }}
                                        id={FloorText.PLAY_COUNT_LABEL}
                                        parseHtml
                                    />
                                </div>
                            </button>
                        }
                    </Translate>
                )}
            </Translate>
        );
    }

    private handleClick = () => {
        const { cabinet, openGame, roomId } = this.props;
        openGame(cabinet.cabinetId, roomId).catch((e) => console.error(e));
    }

    private handleForegroundPreloaded = () => this.setState({ foregroundLoaded: true });
    private handleBackgroundPreloaded = () => this.setState({ backgroundLoaded: true });
}

export default connect(null, mapDispatchToProps)(InstantPlay);
