import React from 'react';
import { Tabs as Tbs } from 'antd';
import { MathPlus, Close, Home } from '@nackle/origami-icons';

const Tabs = ( { children, ...props } ) => {
    const { size, tabPosition, onChange, type, items, className } = props;
    return (
            <Tbs
                { ...props }
                className={ className }
                items={ items }
                onChange={ onChange }
                size={ size }
                tabPosition={ tabPosition }
                type={ type }
            >
            </Tbs>
    );
};
Tabs.Icons = ( { children, ...props } ) => {
    const { size, tabPosition, items } = props;
    const SwitchHander =  ( {  ...props } ) => {
        const { item } = props;
        switch ( size ) {
             case 'small':
                 return  ( 
                            <span>
                                <div> <Home width={ 16 } height={ 16 } /> </div>
                                <div> { item.label } </div>
                            </span> 
                        );
             case 'middle':
                 return (
                            <span>
                                <div> <Home width={ 20 } height={ 20 } /> </div>
                                <div> { item.label } </div>
                            </span> 
                        );
             case 'large':
                 return  (
                            <span>
                                <div> <Home width={ 24 } height={ 24 } /> </div>
                                <div> { item.label } </div>
                            </span>
                        );
             default:
                 return  (
                            <span>
                                <div> <Home width={ 20 } height={ 20 } /> </div>
                                <div> { item.label } </div>
                            </span> 
                        );
        }
    };

    return (
            <Tbs
                { ...props }
                size={ size }
                tabPosition={ tabPosition }
                items={ items.map( ( item ) => {
                   
                    return (
                        {
                            label: ( <SwitchHander item = { item }/> ), 
                            key: item.key,
                            disabled: item.disabled ? item.disabled : false,
                            children: item.children
                        }
                      );
                } ) }
            />
    );
};
Tabs.Card = ( { children, ...props } ) => {
    const { size, items, onChange, activeKey, onEdit } = props;
        switch ( size ) {
            case 'small':
                return <Tbs
                addIcon={ <MathPlus fill='#59606D' width='16' height='16' /> }
                onChange={ onChange }
                size={ size }
                type='editable-card'
                activeKey={ activeKey }
                onEdit={ onEdit }
                items={ items.map( ( item ) => {
                    return (
                        {
                            label: item.label,
                            key: item.key,
                            disabled: item.disabled ? item.disabled : false,
                            children: item.children,
                            closeIcon: <Close fill='#59606D' width='16' height='16' />
                        }
                    );
                } ) }
                >
            </Tbs>;
            case 'middle':
                return <Tbs
                            addIcon={ <MathPlus fill='#59606D' width='20' height='20' /> }
                            onChange={ onChange }
                            size={ size }
                            type='editable-card'
                            activeKey={ activeKey }
                            onEdit={ onEdit }
                            items={ items.map( ( item ) => {
                                return (
                                    {
                                        label: item.label,
                                        key: item.key,
                                        disabled: item.disabled ? item.disabled : false,
                                        children: item.children,
                                        closeIcon: <Close fill='#59606D' width='20' height='20' />
                                    }
                                );
                            } ) }
                            >
            </Tbs>;
            case 'large':
                return  <Tbs
                            addIcon={ <MathPlus fill='#59606D' width='24' height='24' /> }
                            onChange={ onChange }
                            size={ size }
                            type='editable-card'
                            activeKey={ activeKey }
                            onEdit={ onEdit }
                            items={ items.map( ( item ) => {
                                return (
                                    {
                                        label: item.label,
                                        key: item.key,
                                        disabled: item.disabled ? item.disabled : false,
                                        children: item.children,
                                        closeIcon: <Close fill='#59606D' width='24' height='24' />
                                    }
                                );
                            } ) }
                            >
            </Tbs>;
            default:
                return <Tbs
                            addIcon={ <MathPlus fill='#59606D' width='20' height='20' /> }
                            onChange={ onChange }
                            size={ size }
                            type='editable-card'
                            activeKey={ activeKey }
                            onEdit={ onEdit }
                            items={ items.map( ( item ) => {
                                return (
                                    {
                                        label: item.label,
                                        key: item.key,
                                        disabled: item.disabled ? item.disabled : false,
                                        children: item.children,
                                        closeIcon: <Close fill='#59606D' width='20' height='20' />
                                    }
                                );
                            } ) }
                            >
            </Tbs>;
        }
};  
export { Tabs };