import React from 'react';
import PropTypes from 'prop-types';
import { Transfer as AntTransfer } from 'antd';
import { ChevronLeft, ChevronRight } from '@nackle/origami-icons';

const Transfer = ( { ...props } ) => {
    const { searchPlaceholder } = props;

    return (
        <AntTransfer
            locale={ { searchPlaceholder: searchPlaceholder } }
            operations={ [ ChevronLeft, ChevronRight ] }
            { ...props }
        />
    );
};

Transfer.propTypes = {
    /** localized/translated search string */
    searchPlaceholder: PropTypes.string
};
export { Transfer };