import mapKeys from 'lodash/mapKeys';
import { createReducer } from '../../util/createReducer';
import { errorLoading, loaded, loading, notLoaded } from '../../util/Loadable';
import { ArcadeInfo, CabinetView, GamesState, RoomView } from './types';

const initialState: GamesState = {
    arcadeList: notLoaded(),
    cabinetListsByArcade: {},
    cabinetsById: {},
    roomsById: {},
    roomListsByArcade: {},
};

export const games = createReducer(initialState, {
    ARCADE_LIST_LOADING: (state: GamesState) => ({
        ...state,
        arcadeList: loading(state.arcadeList),
    }),

    ARCADE_LIST_ERROR: (state: GamesState, error: any) => ({
        ...state,
        arcadeList: errorLoading(error, state.arcadeList),
    }),

    ARCADE_LIST_LOADED: (state: GamesState, payload: ArcadeInfo[]) => ({
        ...state,
        arcadeList: loaded(payload),
    }),

    ARCADE_CABINET_LIST_LOADING: (state: GamesState, { arcadeId }: { arcadeId: string }) => ({
        ...state,
        cabinetListsByArcade: {
            ...state.cabinetListsByArcade,
            [arcadeId]: loading(state.cabinetListsByArcade[arcadeId]),
        },
    }),

    ARCADE_CABINET_LIST_ERROR: (state: GamesState, { arcadeId, error }: { arcadeId: string, error: any }) => ({
        ...state,
        cabinetListsByArcade: {
            ...state.cabinetListsByArcade,
            [arcadeId]: errorLoading(error, state.cabinetListsByArcade[arcadeId]),
        },
    }),

    ARCADE_CABINET_LIST_LOADED: (state: GamesState, { arcadeId, cabinets }: { arcadeId: string, cabinets: CabinetView[] }) => ({
        ...state,
        cabinetListsByArcade: {
            ...state.cabinetListsByArcade,
            [arcadeId]: loaded(cabinets),
        },
    }),

    ARCADE_ROOM_LIST_LOADING: (state: GamesState, { arcadeId }: { arcadeId: string }) => ({
        ...state,
        roomListsByArcade: {
            ...state.roomListsByArcade,
            [arcadeId]: loading(state.roomListsByArcade[arcadeId]),
        },
    }),

    ARCADE_ROOM_LIST_ERROR: (state: GamesState, { arcadeId, error }: { arcadeId: string, error: any }) => ({
        ...state,
        roomListsByArcade: {
            ...state.roomListsByArcade,
            [arcadeId]: errorLoading(error, state.roomListsByArcade[arcadeId]),
        },
    }),

    ARCADE_ROOM_LIST_LOADED: (state: GamesState, { arcadeId, rooms }: { arcadeId: string, rooms: RoomView[] }) => ({
        ...state,
        roomListsByArcade: {
            ...state.roomListsByArcade,
            [arcadeId]: loaded(rooms),
        },
    }),

    // Add cabinets to the cabinetsById map
    ARCADE_CABINETS_SEEN: (state: GamesState, { cabinets }: { cabinets: CabinetView[] }) => ({
        ...state,
        cabinetsById: {
            ...state.cabinetsById,
            ...mapKeys(cabinets, (cabinet: CabinetView) => cabinet.cabinetId),
        },
    }),

    // Add rooms to the roomsById map
    ARCADE_ROOMS_SEEN: (state: GamesState, { rooms }: { rooms: RoomView[] }) => ({
        ...state,
        roomsById: {
            ...state.roomsById,
            ...mapKeys(rooms, (room: RoomView) => room.id),
        },
    }),
});
