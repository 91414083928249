import { GameLoaderController, TranslationLoader } from '@nackle/arcade-core';
import '@nackle/paper/dist/index.css';
import { Games } from '@nackle/arcade-core/src';
import { DeckWrapper } from 'components/DeckWrapper';
import React from 'react';
import { Provider } from 'react-redux';
import { appStore } from './app-store';
import './app.less';
import ArcadeIndex from './arcade-index/ArcadeIndex';
import { IMG_AWARD_SWIRL, LOADER_URL } from './assets';
import Auth from './auth';
import { ProjectKeys } from './translation-data';

const App = () => (
    <Provider store={appStore}>
        <Auth>
            <DeckWrapper>
                <ArcadeIndex />
                <TranslationLoader projectKeys={[ProjectKeys.ARCADE_UI, Games.cmxKeys.ARCADE_DATA_PROJECT_KEY]} />
                <GameLoaderController
                    imageUrls={{ IMG_AWARD_SWIRL }}
                    loaderUrl={LOADER_URL}
                />
            </DeckWrapper>
        </Auth>
    </Provider>
);

export default App;
