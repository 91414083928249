// Key in CMX where we can find translated names for games
export const ARCADE_DATA_PROJECT_KEY = 'Arcades';

// The commented-out functions represent keys formerly present in the Arcade API when it was still talking to CMX, but
// not yet used in the UI. I'm leaving them here to record the previous convention should we choose to adopt it again.

export const formatCmxRomDescKey = (id: string) => `rom.${id}.description`;
export const formatCmxRomInstructionsKey = (id: string) => `rom.${id}.instructions`;
export const formatCmxRomNameKey = (id: string) => `rom.${id}.name`;
// export const formatCmxArcadeNameKey = (id: string) => `arcade.${id}.name`;
export const formatCmxRomThumbnailAltKey = (id: string) => `rom.${id}.alt`;
export const formatCmxRomBannerAltKey = (id: string) => `rom.banner.${id}.alt`;
export const formatCmxRoomNameKey = (id: string) => `room.${id}.name`;

export const formatCmxCabinetDescKey = (id: string) => `cabinet.${id}.description`;
export const formatCmxCabinetInstructionsKey = (id: string) => `cabinet.${id}.instructions`;
export const formatCmxCabinetNameKey = (id: string) => `cabinet.${id}.name`;
export const formatCmxCabinetThumbnailAltKey = (id: string) => `cabinet.${id}.alt`;
export const formatCmxCabinetBannerAltKey = (id: string) => `cabinet.banner.${id}.alt`;

export enum CabinetContentType {
    DESCRIPTION = 'description',
    INSTRUCTIONS = 'instructions',
    NAME = 'name',
}

export const CABINET_CONTENT_KEY_FORMATTERS: {
    [index in CabinetContentType]: {
        cabinet: (cabinetId: string) => string;
        rom: (romId: string) => string;
    }
} = {
    [CabinetContentType.DESCRIPTION]: { cabinet: formatCmxCabinetDescKey, rom: formatCmxRomDescKey },
    [CabinetContentType.INSTRUCTIONS]: { cabinet: formatCmxCabinetInstructionsKey, rom: formatCmxRomInstructionsKey },
    [CabinetContentType.NAME]: { cabinet: formatCmxCabinetNameKey, rom: formatCmxRomNameKey },
};
