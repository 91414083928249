import React from 'react';
import PropTypes from 'prop-types';
import { Progress as AntProgress } from 'antd';
import { CircleCheckFilled, CircleCloseFilled, CloseLarge, Check } from '@nackle/origami-icons';

const Progress = ( { ...props } ) => {
    let { size, status, type, percent, displayPercent } = props;

    if ( !size ) {
        size = 'medium';
    }

    // Maps size prop values to width/height values
    const circleStyleSize = ( size ) => {
        switch ( size ) {
            case 'small':
                return 76;
            case 'medium':
                return 120;
            case 'large':
                return 154;
            default:
                return 120;
        }
    };

    // Maps size prop values to circle stroke width values to end up with a 10px stroke every time.
    const circleStrokeWidth = ( size ) => {
        switch ( size ) {
            case 'small':
                return 15;
            case 'medium':
                return 9;
            case 'large':
                return 7;
            default:
                return 9;
        }
    };

    if ( type === 'circle' || type === 'dashboard' ) {

        if ( type === 'dashboard' ) {
            if ( props.className ) {
                props.className += ' ant-progress-dashboard';
            } else {
                props.className = 'ant-progress-dashboard';
            }
        }

        //Update icons for success and exception statuses
        if ( ( ( status && status === 'success' ) || percent && percent === 100 ) && !displayPercent ) {
            props.format = () => <Check />;
        } else if ( ( status && status === 'exception' ) && !displayPercent ) {
            props.format = () => <CloseLarge />;
        } else if ( displayPercent && !props.format ) {
            props.format = () => percent;
            if ( props.className ) {
                props.className += ' ant-progress-small-percent';
            } else {
                props.className = 'ant-progress-small-percent';
            }
        }

        //Add class in order to target percentage symbol
        if ( ( ( percent && percent < 100 || percent === 0 )
            || percent > 100 )
            && !props.format ) { //Allows us to make the % sign smaller, will ignore if format is set to something custom
            props.format = () => percent;
            if ( props.className ) {
                props.className += ' ant-progress-small-percent';
            } else {
                props.className = 'ant-progress-small-percent';
            }
        }
        if ( size ) {
            const componentSize = circleStyleSize( size );
            const strokeSize = circleStrokeWidth( size );
            props.strokeWidth = strokeSize;
            props.size = componentSize;
            if ( props.className ) {
                props.className += ` ant-progress-circle-${ size }`;
            } else {
                props.className = `ant-progress-circle-${ size }`;
            }
        }

        if ( status ) {
            if ( status === 'incomplete' ) {
                props.strokeColor = '#DE1B1B';
            }
        }
    } else { //linear and steps versions
        if ( size ) {
            if ( props.className ) {
                props.className += ` ant-progress-${ size }`;
            } else {
                props.className = `ant-progress-${ size }`;
            }
        }
        if ( ( status && status === 'success' ) || percent && percent === 100 ) {
            props.format = () => <CircleCheckFilled />;
        } else if ( status && status === 'exception' ) {
            props.format = () => <CircleCloseFilled />;
        }
    }
    const progressProps = props;
    delete progressProps.displayPercent;
    return (
        <AntProgress { ...progressProps } ></AntProgress>
    );
};
Progress.propTypes = {
    /** size of progress */
    size: PropTypes.oneOf( [ 'small', 'medium', 'large' ] ), 
    /** optional status of progress */
    status: PropTypes.oneOf( [ 'success', 'exception', 'incomplete' ] ), 
    /** type/shape of progress*/
    type: PropTypes.oneOf( [ 'circle', 'dashboard', 'line' ] ), 
    /** percent complete */
    percent: PropTypes.number, 
    /** display percent instead of icon when 100% */
    displayPercent: PropTypes.bool
};
Progress.defaultProps = {
    size: 'medium'
};
export { Progress };
