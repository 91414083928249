const FALLBACK_LOCALE = 'en-US';

/**
 * Deconstruct the mysterious "Locale" value, which might be a string, an object, or completely absent!
 * TODO: Consider handling this inside setLanguageBundle() instead to simplify matters for library users.
 */
export function resolveLocaleCode( locale ) {
    // This carefully-constructed fallback chain ensures that:
    // 1) No "empty" locale is returned - In the worst case, the user is assumed to be an Anglophone.
    // 2) string-typed truthy locales are always returned
    // 3) object-typed locale values give preference to the "intlCode" property (if present),
    //    followed by the "code" property (again, if present)
    return (
        typeof locale === 'string' && locale ||
        typeof locale === 'object' && (
            'intlCode' in locale && locale.intlCode ||
            'code' in locale && locale.code
            )
        ) ||
        FALLBACK_LOCALE;
}
