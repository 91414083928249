import { apiRootUrl, Env, isEnv } from '@nackle/game-loader-comm-api';
import { getEnvironment } from './environments';

export const ENV: Env = (() => {
    const env = getEnvironment() || 'prod';

    if (!isEnv(env)) {
        throw new Error(`${env} is not a valid Env`);
    }

    return env;
})();

export const KONG_URL = apiRootUrl(ENV);
