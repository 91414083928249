/* tslint:disable:max-line-length */
import React from 'react';
import './icons.less';

export function CloseIcon() {
    return (
        <svg viewBox="0 0 24 24" className="cpd-icon">
            <path d="M14.8,12l8.6-8.6c0.8-0.8,0.8-2,0-2.8c-0.8-0.8-2-0.8-2.8,0L12,9.2L3.4,0.6c-0.8-0.8-2-0.8-2.8,0c-0.8,0.8-0.8,2,0,2.8 L9.2,12l-8.6,8.6c-0.8,0.8-0.8,2,0,2.8C1,23.8,1.5,24,2,24s1-0.2,1.4-0.6l8.6-8.6l8.6,8.6C21,23.8,21.5,24,22,24s1-0.2,1.4-0.6 c0.8-0.8,0.8-2,0-2.8L14.8,12z"/>
        </svg>
    );
}

export const StarCircleIcon = (props: React.SVGProps<SVGSVGElement>) => (
    <svg width={24} height={24} viewBox="0 0 24 24" {...props}>
        <path d="M12,0A12,12,0,0,0,0,12A12,12,0,0,0,12,24A12,12,0,0,0,24,12A12,12,0,0,0,12,0z M12,3L14,9.2L20.5,9.2L15.3,13.1L17.3,19.3L12,15.4L6.7,19.3L8.7,13.1L3.4,9.2L10,9.2L12,3z"/>
    </svg>
);
