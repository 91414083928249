import { createReducer } from '../../util/createReducer';
import { loaded, notLoaded } from '../../util/Loadable';
import { PromotedGame, PromotionState } from './types';

const initialState: PromotionState = {
    currentPromotion: notLoaded(),
};

export const promotion = createReducer(initialState, {
    PROMOTION_SET_CABINET: (state, promotedGame: PromotedGame): PromotionState => ({
        ...state,
        currentPromotion: loaded(promotedGame),
    }),
    PROMOTION_SET_NOTHING: (state): PromotionState => ({
        ...state,
        currentPromotion: loaded(undefined),
    }),
});
