import { createReducer, DispatchFor } from '../../util/createReducer';
import { GameplayInProgress, OpenGame, PlayState } from './types';

const initialState: PlayState = {
    currentGameplay: undefined,
    openGame: undefined,
};

export const play = createReducer(initialState, {
    PLAY_OPENED: (state: PlayState, currentlyPlaying: OpenGame) => ({
        ...state,
        currentGameplay: undefined,
        openGame: currentlyPlaying,
    }),
    PLAY_STARTED: (state: PlayState, currentlyPlaying: GameplayInProgress) => ({
        ...state,
        currentGameplay: currentlyPlaying,
    }),
    PLAY_UPDATED: (state: PlayState, { score }: { score: number | null }) => ({
        ...state,
        currentGameplay: state.currentGameplay && {
            ...state.currentGameplay,
            score,
        },
    }),
    PLAY_COMPLETED: (state: PlayState, { score }: { score: number | null }) => ({
        ...state,
        currentGameplay: state.currentGameplay && {
            ...state.currentGameplay,
            complete: true,
            score,
        },
    }),
    PLAY_CLOSED: (state: PlayState) => ({
        ...state,
        currentGameplay: undefined,
        openGame: undefined,
    }),
});

export type PlayDispatch = DispatchFor<typeof play>;
