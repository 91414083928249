/**
 * Get environment for resources
 */

export function getEnvironment() {
    if (/localhost/.test(window.location.hostname)) {
        return 'qa';
    }

    return window.location.hostname.toLowerCase().split('.').find((chunk) => {
        return ['qa', 'pprd'].indexOf(chunk) >= 0;
    } );
}
