import React from 'react';
import PropTypes from 'prop-types';
import { Modal as AntdModal } from 'antd';
import { Close, DangerFilled, InfoFilled, CircleCheckFilled, ErrorFilled } from '@nackle/origami-icons';

const Title = ( props ) => {
    const { title, subTitle } = props;
    if ( !title && !subTitle ) {
        return <></>;
    }
    if ( !subTitle ) {
        return <>{ title }</>;
    }
    return (
        <>
            { title }
            <div className={ 'ant-modal-subtitle' }>
                { subTitle }
            </div>
        </>
    );
};

const Modal = ( { children, ...props } ) => {
    const { title, subTitle } = props;
    return (
        <AntdModal { ...props } closeIcon={ <Close /> } title={ <Title title={ title } subTitle={ subTitle } /> }>
            { children }
        </AntdModal>
    );
};

Modal.confirm = ( config ) => {
    const { title, subTitle } = config;
    return AntdModal.confirm( {
        ...config,
        icon: <span className="anticon"><DangerFilled className="ant-modal-confirm-icon"/></span>,
        title: <Title title={ title } subTitle={ subTitle } /> 
    } );
};

Modal.info = ( config ) => {
    const { title, subTitle } = config; 
    return AntdModal.info( {
        ...config,
        icon: <span className="anticon"><InfoFilled className="ant-modal-info-icon"/></span>,
        title: <Title title={ title } subTitle={ subTitle } /> 
    } );
};

Modal.success = ( config ) => {
    const { title, subTitle } = config;
    return AntdModal.success( {
        ...config,
        icon: <span className="anticon"><CircleCheckFilled className="ant-modal-success-icon"/></span>,
        title: <Title title={ title } subTitle={ subTitle } /> 
    } );
};

Modal.error = ( config ) => {
    const { title, subTitle } = config;
    return AntdModal.error( {
        ...config,
        icon: <span className="anticon"><ErrorFilled className="ant-modal-error-icon"/></span>,
        title: <Title title={ title } subTitle={ subTitle } /> 
    } );
};

Modal.warning = ( config ) => {
    const { title, subTitle } = config;
    return AntdModal.warning( {
        ...config,
        icon: <span className="anticon"><DangerFilled className="ant-modal-confirm-icon"/></span>,
        title: <Title title={ title } subTitle={ subTitle } /> 
    } );
};

Modal.propTypes = {
    /** modal title */
    title: PropTypes.node,
    /** modal subtitle */
    subTitle: PropTypes.node
};
export { Modal };