import { Arcade, CompanySetup } from '../../api';
import { Session, SessionAppState } from '../session';
import { DispatchFor } from '../../util/createReducer';
import { dataOrThrow } from '../../util/Loadable';
import { games } from './reducer';
import { getArcadeList, getCabinetList, getRoomList } from './selectors';
import { GamesAppState } from './types';

export type GamesDispatch = DispatchFor<typeof games>;

export const loadArcades = () =>
    async (dispatch: GamesDispatch, getState: () => GamesAppState & SessionAppState) => {
        const client = Session.getAxios(getState());

        dispatch({ type: 'ARCADE_LIST_LOADING' });
        try {
            const { arcades } = await Arcade.listArcades(client);
            dispatch({ type: 'ARCADE_LIST_LOADED', payload: arcades });
            return dataOrThrow(getArcadeList(getState()));
        } catch (error) {
            dispatch({ type: 'ARCADE_LIST_ERROR', payload: error });
            throw error;
        }
    };

export const loadArcadeCabinets = (arcadeId: string) =>
    async (dispatch: GamesDispatch, getState: () => GamesAppState & SessionAppState) => {
        const client = Session.getAxios(getState());

        dispatch({ type: 'ARCADE_CABINET_LIST_LOADING', payload: { arcadeId } });
        try {
            const { cabinets } = await Arcade.listArcadeCabinets(client, arcadeId);
            dispatch({ type: 'ARCADE_CABINET_LIST_LOADED', payload: { arcadeId, cabinets } });
            dispatch({ type: 'ARCADE_CABINETS_SEEN', payload: { cabinets } });
            return dataOrThrow(getCabinetList(getState()));
        } catch (error) {
            dispatch({ type: 'ARCADE_CABINET_LIST_ERROR', payload: { arcadeId, error } });
            throw error;
        }
    };

export const loadArcadeRooms = (arcadeId: string) =>
    async (dispatch: GamesDispatch, getState: () => GamesAppState & SessionAppState) => {
        const client = Session.getAxios(getState());

        dispatch({ type: 'ARCADE_ROOM_LIST_LOADING', payload: { arcadeId } });
        try {
            const { rooms } = await Arcade.listArcadeRooms(client, arcadeId);
            dispatch({ type: 'ARCADE_ROOM_LIST_LOADED', payload: { arcadeId, rooms } });
            dispatch({ type: 'ARCADE_ROOMS_SEEN', payload: { rooms } });
            return dataOrThrow(getRoomList(getState()));
        } catch (error) {
            dispatch({ type: 'ARCADE_ROOM_LIST_ERROR', payload: { arcadeId, error } });
            throw error;
        }
    };

export const loadArcadesAndTheirData = (
    // Since this function just delegates to other functions, the unit test will inject mocks
    iLoadArcades: typeof loadArcades = loadArcades,
    iLoadArcadeCabinets: typeof loadArcadeCabinets = loadArcadeCabinets,
    iLoadArcadeRooms: typeof loadArcadeRooms = loadArcadeRooms,
) =>
    async (dispatch: GamesDispatch, getState: () => GamesAppState & SessionAppState) => {
        // Ensure arcades are loaded
        const arcadeList = await iLoadArcades()(dispatch, getState);
        if (!arcadeList.length) {
            console.warn('No arcades found');
            return;
        }

        // Load the cabinets and rooms for all arcades
        await Promise.all(arcadeList.map(({ id }) =>
            Promise.all([
                iLoadArcadeCabinets(id)(dispatch, getState),
                iLoadArcadeRooms(id)(dispatch, getState),
            ]),
        ));
    };
