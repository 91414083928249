// Polyfill promises for IE 11 support (import before everything else!)
import 'regenerator-runtime';
import 'ts-polyfill';
import 'whatwg-fetch';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './app';

const container: any = document.getElementById('app');

if ( container ) {
    const root = createRoot( container );
    root.render( <App /> );
}
