import React from 'react';
import { Typography as AntType } from 'antd';
import classNames from 'classnames';

const subcomponents = {
    Title: ( { weight, children, ...props } ) => props.level === 6 ? (
        props.italic ? (
            <h6 className={ classNames(
                'ant-typography',
                'ant-typography-heading-6',
                weight ? `ant-typography-${ weight }` : ''
            ) }
            >
                <i>{ children }</i>
            </h6>
        ) : (
            <h6 className={ classNames(
                'ant-typography',
                'ant-typography-heading-6',
                weight ? `ant-typography-${ weight }` : ''
            ) }
            >
                { children }
            </h6>
        )
    ) : (
        <AntType.Title
            className={ weight ? `ant-typography-${ weight }` : '' } { ...props } >
            { children }
        </AntType.Title>
    ),
    Text: ( { size, weight, children, ...props } ) => (
        <AntType.Text
            className={ classNames(
                weight ? `ant-typography-${ weight }` : '',
                size ? `ant-typography-size-${ size }` : ''
            ) }
            { ...props }
        >
            { children }
        </AntType.Text>
    ),
    Paragraph: ( { weight, children, ...props } ) => (
        <AntType.Paragraph className={ weight ? `ant-typography-${ weight }` : '' } { ...props }>
            { children }
        </AntType.Paragraph>
    ),
    Link: ( { weight, children, ...props } ) => (
        <AntType.Link className={ weight ? `ant-typography-${ weight }` : '' } { ...props }>
            { children }
        </AntType.Link>
    ),
    Label: ( { level, weight, children, ...props } ) => (
        <AntType.Text
            className={ classNames(
                'ant-typography',
                level ? `ant-typography-label-${ level }` : 'ant-typography-label-1',
                weight ? `ant-typography-${ weight }` : ''
            ) }
            { ...props }
        >
            { children }
        </AntType.Text>
    )
};

const Typography = ( { children, ...props } ) => {
    return <AntType { ...props }>{ props.children }</AntType>;
};

Object.keys( subcomponents ).forEach( component => {
    Typography[ component ] = subcomponents[ component ];
} );

export default Typography;
