// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import formatDate from './format-date';
import { CURRENT_LOCALE_CODE, languageChangeEvent } from '../translations';

const propTypes = {
    value: PropTypes.oneOfType( [
        PropTypes.string,
        PropTypes.number,
        PropTypes.instanceOf( Date )
    ] ),
    TextComponent: PropTypes.any
};

class FormattedDate extends Component {

    componentDidMount() {
        languageChangeEvent.on( this.onLanguageChange );
    }

    componentWillUnmount() {
        languageChangeEvent.off( this.onLanguageChange );
    }

    onLanguageChange = () => this.forceUpdate();

    render() {
        const {
            value,
            TextComponent = 'span',
            ...props
        } = this.props;

        const locale = CURRENT_LOCALE_CODE;

        return <TextComponent { ...props }>{ value && formatDate( locale, value ) }</TextComponent>;
    }
}

FormattedDate.propTypes = propTypes;
export default FormattedDate;
