export default class Event {

    listeners = [];

    on = ( callback ) => {
        this.listeners.push( callback );
    };

    off = ( callback ) => {
        const index = this.listeners.indexOf( callback );
        if ( index >= 0 ) {
            this.listeners.splice( index, 1 );
        }
    };

    fire = ( data ) => {
        this.listeners.forEach( ( listener ) => {
            if ( typeof listener === 'function' ) {
                listener( data );
            }
        } );
    };
}
