import React from 'react';
import PropTypes from 'prop-types';
import { Popover as Pover, Divider, List } from 'antd';
import { PopoverShell } from './popover-shell';
const Popover = ( { children, ...props } ) => {
    const { data, trigger, title, headerContent, placement, Icon, SectionTitle, className, hideHeader } = props;
    if ( hideHeader ) {
        return (
            <Pover { ...props }>
                { children }
            </Pover> );
    }
    const nackleContent = (
        <div>
            <div className={ className ? `popover-content ${ className }` : 'popover-content' }>{ headerContent }</div>
            <Divider />
            <div>
                <List
                    split={ false }
                    header={ <div>{ SectionTitle } </div> }
                    dataSource={ data }
                    renderItem={ ( item ) => (

                        <List.Item>
                            <List.Item.Meta
                                avatar={
                                    <Icon className="primary-color" />
                                }
                                title={ item.label }
                                description={ item.description }
                            />
                        </List.Item>

                    ) }
                />
            </div>
        </div>
    );
    return (
        <Pover placement={ placement } title={ title } content={ nackleContent } trigger={ trigger } { ...props }>
            { children }
        </Pover>


    );
};
Popover.propTypes = {
    /** list data array */
    data: PropTypes.array,
    /** display trigger for popover */
    trigger: PropTypes.oneOfType( [ PropTypes.array, PropTypes.oneOf( [ 'hover', 'focus', 'click', 'contextMenu' ] ) ] ),
    /** title of popover */
    title: PropTypes.node,
    /** list header content */
    headerContent: PropTypes.node,
    /** placement of popover in relation to trigger */
    placement: PropTypes.oneOf( [ 'top', 'left', 'right', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom' ] ),
    /** List item avatar icon */
    Icon: PropTypes.node,
    /** list section header content */
    SectionTitle: PropTypes.node,
    /** class name */
    className: PropTypes.string,
    /** hide the header and return basic popover */
    hideHeader: PropTypes.bool
};

Popover.shell = PopoverShell;
export { Popover };
