import {
    AppState,
    dataOrElse,
    Games,
    mapData,
    Preferences,
    Promotion,
    ReduxProps,
    RoomView,
    Tokens
} from '@nackle/arcade-core';
import { CabinetEntryView, Token } from '@nackle/arcade-core/src';
import { ItemSelector } from '@nackle/arcade-core/src/util/arrays';
import MainNavbar from 'components/navbar';
import orderBy from 'lodash/fp/orderBy';
import React from 'react';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';
import RoomCabinetList from './arcade-room';
import GameBanner from './banner';
import InstantPlays from './instant-play';
import './ArcadeIndex.less';

const mapStateToProps = () => {
    const getNonEmptyNonFreePlayRooms = createSelector(
        (state: AppState) => Games.getRoomList(state),
        (rooms) => mapData(rooms, (roomList) =>
            roomList.filter((room) => !room.freePlay && room.cabinets.length),
        ),
    );

    return (state: any) => {
        const arcades = Games.getArcadeList(state);
        const nonEmptyNonFreePlayRooms = getNonEmptyNonFreePlayRooms(state);
        const roomsTokens = nonEmptyNonFreePlayRooms.isLoaded &&
            ( nonEmptyNonFreePlayRooms.data.filter((room) => !room.freePlay && room.cabinets.length ) ).map(
                room => {
                    const tokens = dataOrElse(
                        Tokens.countAvailableTokensForRoom(state, room.id ), 0
                    ) || 0;

                    return {
                        ...room,
                        tokens
                    }
                }
            );

        return {
            arcade: arcades,
            mainRoomId: arcades.isLoaded && arcades.data.length > 0 && arcades.data[0].mainRoomId,
            rooms: Games.getRoomList(state),
            nonEmptyNonFreePlayRooms: roomsTokens,
        };
    };
};

const mapDispatchToProps = {
    loadArcadesAndTheirData: Games.loadArcadesAndTheirData,
    loadPreferences: Preferences.loadPreferences,
    loadTokens: Tokens.loadTokens,
    pickARandomCabinetToBePromoted: Promotion.pickARandomCabinetToBePromoted,
};

interface Props {
    selectCabinetEntry?: ItemSelector<CabinetEntryView>;
    selectToken?: ItemSelector<Token>;
}

interface State {
    selectedCabinetId?: string;
    selectedRoomId?: string;
}

class ArcadeIndex extends React.Component<ReduxProps<Props, typeof mapStateToProps, typeof mapDispatchToProps>, State> {
    public state = {
        selectedCabinetId: undefined,
        selectedRoomId: undefined,
    };

    public componentDidMount(): void {
        this.loadArcadeData();
    }

    public render() {
        const { arcade, nonEmptyNonFreePlayRooms } = this.props;

        const rooms = nonEmptyNonFreePlayRooms && arcade.isLoaded &&
            orderBy( [ 'tokens' ], [ 'desc' ] )( nonEmptyNonFreePlayRooms )
                .map( ( room: any ) => (
                    <RoomCabinetList
                        key={ room.id }
                        room={ room }
                        onClickCabinet={ this.handleClickCabinet } />
                ) );

        return (
            <div className="arcade-index">
                <GameBanner />
                <MainNavbar />
                <div className="arcade-section">
                    <div className="arcade-content">
                        <InstantPlays onClickCabinet={this.handleClickCabinet} />
                        {rooms}
                    </div>
                </div>
            </div>
        );
    }

    private handleClickCabinet = (roomId: string, cabinetId: string) => {
        this.setState({
            selectedCabinetId: cabinetId,
            selectedRoomId: roomId,
        });
    }

    private loadArcadeData() {
        this.props.loadPreferences().catch((e) => console.error(e));

        Promise.all([
            this.props.loadArcadesAndTheirData(),
            this.props.loadTokens(),
        ])
            .then(() => this.props.pickARandomCabinetToBePromoted({
                selectCabinetEntry: this.props.selectCabinetEntry,
                selectToken: this.props.selectToken,
            }))
            .catch((e) => console.error(e));
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ArcadeIndex);
