
import React from 'react';
import { Popconfirm as AntdPopconfirm } from 'antd';
import { DangerFilled } from '@nackle/origami-icons';

export const Popconfirm = ( { children, ...props } ) => {
    return (
        <AntdPopconfirm { ...props } icon={ <DangerFilled /> }>
            { children }
        </AntdPopconfirm>
    );
};