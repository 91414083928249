import _routeMatcher from './route-matcher.js';
import _routeParser from './route-parser.js';
import _routeHistory from './route-history.js';
import _Link from './link.jsx';
import _Route from './route.jsx';

export const routeMatcher = _routeMatcher;
export const routeParser = _routeParser;
export const routeHistory = _routeHistory;
export const Link = _Link;
export const Route = _Route;

export default {
    Link,
    Route,
    routeMatcher,
    routeParser,
    routeHistory
};