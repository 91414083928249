import React from 'react';
import PropTypes from 'prop-types';
import { Avatar as Avtr } from 'antd';

const AvatarInfo = ( { children, ...props } ) => {
    const { firstName, lastName, pronouns, metaData, size, className } = props;
    const copyOfProps = props;
    delete copyOfProps.firstName;
    delete copyOfProps.lastName;
    delete copyOfProps.pronouns;
    delete copyOfProps.metaData;
    delete copyOfProps.size;

    return (
        <div className='avatar-container'>
            { ( () => {
                switch ( size ) {
                    case 'mini':
                        return <Avtr { ...copyOfProps } size={ 24 } className={ className ? `mini-avatar ${ className }` : 'mini-avatar' }>
                            { children }
                        </Avtr>;
                    case 'small':
                        return <Avtr { ...copyOfProps } size={ 36 } className={ className ? `small-avatar ${ className }` : 'small-avatar' }>
                            { children }
                        </Avtr>;
                    case 'medium':
                        return <Avtr { ...copyOfProps } size={ 48 } className={ className ? `medium-avatar ${ className }` : 'medium-avatar' }>
                            { children }
                        </Avtr>;
                    case 'large':
                        return <Avtr { ...copyOfProps } size={ 64 } className={ className ? `large-avatar ${ className }` : 'large-avatar' }>
                            { children }
                        </Avtr>;
                    default:
                        return <Avtr { ...copyOfProps } size={ 48 } className={ className ? `medium-avatar ${ className }` : 'medium-avatar' }>
                            { children }
                        </Avtr>;
                }
            } )() }
            <div className='avatar-info-container'>
                <div className={ size === 'mini' ? 'avatar-info-name-mini' : 'avatar-info-name' }>
                    <span>{ firstName }</span>&nbsp;
                    <span>{ lastName }</span>&nbsp;
                    <div className='avatar-info-pronouns'>
                        ({ `${ pronouns }` })
                    </div>
                </div>
                <div className='avatar-info-content'>
                    { metaData }
                </div>
            </div>
        </div>
    );
};

AvatarInfo.propTypes = {
    /** Size of Avatar */
    size: PropTypes.oneOf( [ 'mini', 'small', 'medium', 'large' ] ),
    /** Class name */
    className: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    pronouns: PropTypes.string,
    /** text to display under names and pronouns */
    metaData: PropTypes.string,
    /** Children - generally initial(s) for fallback when no avatar image is present */
    children: PropTypes.node

};
AvatarInfo.defaultProps = {
    size: 'medium'
};
export default AvatarInfo;


