import { AppState, ReduxProps, Session } from '@nackle/arcade-core';
import AppRoot from '@nackle/deck/src/deck/app-root/app-root';
import { auth } from 'config';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import './DeckWrapper.less';

const mapStateToProps = (state: AppState) => ({
    authState: Session.getAuthState(state),
    localeLoaded: Session.getLocale(state)
});

const mapDispatchToProps = {
};

interface State {
    error?: Error;
}

interface Props {
    children?: any
}

class DeckWrapper extends Component<ReduxProps<Props, typeof mapStateToProps, typeof mapDispatchToProps>, State> {
    public state: State = {};

    public componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
        console.error('Error in Deck:', error);
        this.setState({ error });
    }

    public render() {
        const { authState, localeLoaded, children } = this.props;
        const { error } = this.state;

        //@ts-ignore
        const locale = localeLoaded.data

        return authState && !error && (
            <AppRoot
                auth={auth}
                user={authState}
                locale={locale || 'en-US'}
                guest={ false }
                footer={ false }
                leftNav={ false }
            >
                { children }
            </AppRoot>
        ) ||
            <div />;
    }
}

const decorated = connect(mapStateToProps, mapDispatchToProps)(DeckWrapper);
export { decorated as DeckWrapper };
