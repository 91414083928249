import { createReducer } from '../../util/createReducer';
import { loaded, notLoaded } from '../../util/Loadable';
import {
    AccessTokenPayload,
    ClientCredentialsPayload,
    SessionState,
    SET_USER_DATA,
    USER_LOGOUT,
    AuthState,
} from './types';

export const userState: SessionState = {
    locale: notLoaded(),
};

export const session = createReducer(userState, {
    [SET_USER_DATA]: (state: SessionState, { access_token, accessTokenPayload, authState }: { access_token?: string, accessTokenPayload?: AccessTokenPayload, authState?: AuthState }): SessionState => ({
        ...state,
        access_token,
        accessTokenPayload,
        authState,
        locale: (authState && authState.claims.locale) ? loaded(authState.claims.locale) : state.locale,
    }),

    [USER_LOGOUT]: (state: SessionState): SessionState => ({ ...userState }),

    SET_ACCESS_TOKEN: (state: SessionState, { access_token, accessTokenPayload }: { access_token: string, accessTokenPayload: AccessTokenPayload | ClientCredentialsPayload }): SessionState => ({
        ...state,
        access_token,
        accessTokenPayload,
    }),

    SET_LOCALE: (state: SessionState, locale: string): SessionState => ({
        ...state,
        locale: loaded(locale),
    }),
});
