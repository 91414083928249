import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import './nackle-tile.less';

const NackleTileTriple = ( { className, children } ) => {
    const tileClasses = classNames( {
        'nackle-tile': true,
        'nackle-tile-triple': true,
        [ className ]: className ? true : false
    } );
    return (
        <div className={ tileClasses }>
            { children }
        </div>
    );
};
NackleTileTriple.propTypes = {
    /** optional class name */
    className: PropTypes.string
};

export { NackleTileTriple };