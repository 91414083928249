import type { Loadable } from '../..';
import { mapData } from '../../util/Loadable';
import { Games, GamesAppState } from '../games';
import { Tokens, TokensAppState, TokenState } from '../tokens';
import { GameplayInProgress, OpenGame, PlayAppState } from './types';

export const getOpenCabinet = ({ play }: PlayAppState): OpenGame | undefined => play.openGame;

export const getCurrentGameplay = ({ play }: PlayAppState): GameplayInProgress | undefined => play.currentGameplay;

/**
 * For a particular game cabinet in a room, figure out which tokens would be spent if the user chose to play it.
 * @return Undefined if insufficient tokens; otherwise the list of token IDs
 */
export const getTokensIdsToPlay = (state: GamesAppState & TokensAppState, cabinetId: string, roomId: string): string[] | undefined => {
    const cost = Games.getCabinetCost(state, cabinetId, roomId);

    const availableTokens = Tokens.getAvailableTokensForRoom(state, roomId);
    if (!availableTokens.isLoaded) {
        return undefined;
    }

    const tokenIds = availableTokens.data.map((token) => token.id);
    if (tokenIds.length < cost) {
        return undefined;
    }

    return tokenIds.slice(0, cost);
};

export const countCabinetPlaysAvailable = (state: GamesAppState & TokensAppState, cabinetId: string, roomId: string): number => {
    const cost = Games.getCabinetCost(state, cabinetId, roomId);

    const availableTokens = Tokens.getAvailableTokensForRoom(state, roomId);
    if (!availableTokens.isLoaded) {
        return 0;
    }

    return Math.floor(availableTokens.data.length / cost);
};

/**
 * Figure out which tokens the user would spend if they chose to re-play the current game in progress.
 * @return Undefined if insufficient tokens; otherwise the list of token IDs.
 */
export const getOpenGameTokenIds = (state: PlayAppState & GamesAppState & TokensAppState) => {
    const gameInProgress = getOpenCabinet(state);
    if (!gameInProgress) {
        return undefined;
    }

    return getTokensIdsToPlay(state, gameInProgress.cabinetId, gameInProgress.roomId);
};

/**
 * Number of tokens to show on the Arcade widget
 */
export const countAvailableNonInstantPlayTokens = (state: TokensAppState & GamesAppState): Loadable<number> =>
    mapData(Tokens.getTokens(state), (tokenList) =>
        tokenList
            .filter((token) => {
                return token.state === TokenState.AVAILABLE
                    && !Games.isRoomInstantPlay(state, token.roomId)
                    && Games.roomHasCabinets(state, token.roomId)
            })
            .length
    );
