import React from 'react';
import routeHistory from './route-history.js';
import classNames from 'classnames';

class Link extends React.Component {
    constructor( props ) {
        super( props );

        this.state = {
            waiting: false
        };
    }

    componentWillUnmount() {
        this.unmounted = true;
    }

    handleClick = ( e ) => {
        e.preventDefault();

        this.setState( { waiting: true } );

        if ( this.props.back ) {
            routeHistory.back().then( () => {
                if ( !this.unmounted ) {
                    this.setState( { waiting: false } );
                }
            } );
        } else if ( this.props.replace ) {
            routeHistory.replace( this.props.to, { props: this.props.toProps } ).then( () => {
                if ( !this.unmounted ) {
                    this.setState( { waiting: false } );
                }
            } );
        } else {
            routeHistory.push( this.props.to, { props: this.props.toProps } ).then( () => {
                if ( !this.unmounted ) {
                    this.setState( { waiting: false } );
                }
            } );
        }
    }

    render() {
        const className = classNames( this.props.className, {
            waiting: this.props.waiting
        } );

        return (
            <a
                className={ className }
                id={ this.props.id }
                href={ this.props.to ? `${ routeHistory.base() }${ this.props.to }` : undefined }
                onClick={ this.handleClick }
            >
                {this.props.children}
            </a>
        );
    }
}

export default Link;
