import { AxiosInstance } from 'axios';
import { BundledStringsResponse, TranslatedStringsResponse } from './types';

export const CMX_API_ROOT = `/v2/cmx`;

// This could return a LOT of strings
// Also, this doesn't work because the browser tries to make a preliminary OPTIONS request without the Authorization
// header, which fails in CMX because it doesn't contain a valid Authorization header.
export const listAllTranslatedProjectStrings = async (
    client: AxiosInstance,
    projectName: string,
    localeCode: string,
) =>
    (await client.get<TranslatedStringsResponse>(`${CMX_API_ROOT}/projects/${projectName}/keys/locales/${localeCode}`)).data;

export const getTranslatedProjectStrings = async (
    client: AxiosInstance,
    projectName: string,
    localeCodes: string | string[],
    keys?: string[],
) =>
    (await client.post<BundledStringsResponse>(`${CMX_API_ROOT}/bundle/${projectName}`, { keys, locales: localeCodes })).data;
