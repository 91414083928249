import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { COUNTRY_CODES, S3_FLAG_BUCKET_URL } from '../constants';

const propTypes = {
	code: PropTypes.string.isRequired,
	className: PropTypes.string,
	TextComponent: PropTypes.any
};

class Flag extends Component {
	render() {
		const {
			code,
			TextComponent = 'div',
			...props
		} = this.props;

		let flagImageUrl = '';
		if ( COUNTRY_CODES.includes( code.toLowerCase() ) ) {
			flagImageUrl = `${ S3_FLAG_BUCKET_URL }/${ code.toLowerCase() }.svg`;
		} else {
			flagImageUrl = `${ S3_FLAG_BUCKET_URL }/null.svg`;
		}

		return (
			<TextComponent data-key={ code } { ...props }>
				<img src={ flagImageUrl } alt={ `${ code } flag` }/>
			</TextComponent>
		);
	}
}

Flag.propTypes = propTypes;
export default Flag;
