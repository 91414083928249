import React from 'react';
import { TreeSelect as AntTreeSelect } from 'antd';
import { CaretDownSmall } from '@nackle/origami-icons';

const TreeSelect = ( { ...props } ) => {

    const iconProp = CaretDownSmall;

    props.switcherIcon = iconProp;

    return (
        <AntTreeSelect { ...props } ></AntTreeSelect>
    );
};
TreeSelect.Checkable = ( { children, ...props } ) => {
    const { type } = props;
    return (
        <div>
            { type === 'checkbox' &&
                <AntTreeSelect { ...props }></AntTreeSelect>
            }
            {
                type === 'radio' &&
                <AntTreeSelect popupClassName='select-radio' { ...props } ></AntTreeSelect>
            }
        </div>
    );
};

export { TreeSelect };
