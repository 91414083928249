import routeParser from './route-parser.js';

export default ( items ) => {
    const matchers = ( items || [] ).map( ( item ) => {
        return { item, isMatch: routeParser( item.pattern ) };
    } );

    return ( pathname ) => {
        for ( let i = 0; i < matchers.length; i++ ) {
            const params = matchers[ i ].isMatch( pathname );

            if ( params ) {
                return { 
                    item: matchers[ i ].item,
                    params: params
                };
            }   
        }
    };
};