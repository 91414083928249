import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

const NackleNotation = ( { className, icon, text, emphasis, state } ) => {
    const tileClasses = classNames( {
        'nackle-notation': true,
        'emphasis': emphasis ? true : false,
        [ state ]: state ? true : false,
        [ className ]: className ? true : false
    } );
    return (
        <div className={ tileClasses }>
            <div className="notation-icon">
                { icon ? icon : null }
            </div>
            <div className="notation-text">
                { text }
            </div>
        </div>
    );
};

NackleNotation.propTypes = {
    /** class name for component */
    className: PropTypes.string,
    /** icon for display on left side of notation */
    icon: PropTypes.node,
    /** text/node to display on right side of notation */
    text: PropTypes.node,
    /** display emphasized notation */
    emphasis: PropTypes.bool,
    /** state of notation */
    state: PropTypes.oneOf( [ 'error', 'info', 'success', 'default' ] )
};

export { NackleNotation };