import { Loadable, loaded, mapData, NOT_LOADED } from '../../util/Loadable';
import { Translations, TranslationsAppState } from '../translations';
import { CABINET_CONTENT_KEY_FORMATTERS, CabinetContentType, formatCmxRoomNameKey } from './cmxKeys';
import { ArcadeInfo, CabinetView, GamesAppState, RoomView } from './types';

export const getArcadeId = (state: GamesAppState) =>
    state.games.arcadeList.isLoaded &&
    state.games.arcadeList.data.length > 0 &&
    state.games.arcadeList.data[0].id ||
    undefined;

export const getArcadeList = (state: GamesAppState): Loadable<ArcadeInfo[]> => state.games.arcadeList;

export const getCabinetList = (state: GamesAppState): Loadable<CabinetView[]> => {
    const arcadeId = getArcadeId(state);
    return arcadeId &&
        state.games.cabinetListsByArcade[arcadeId] ||
        NOT_LOADED;
};

export const getRoomList = (state: GamesAppState): Loadable<RoomView[]> => {
    const arcadeId = getArcadeId(state);
    return arcadeId &&
        state.games.roomListsByArcade[arcadeId] ||
        NOT_LOADED;
};

export const getRoomName = (state: GamesAppState & TranslationsAppState, roomId: string): Loadable<string> => {
    const roomName = Translations.getTranslation(state, formatCmxRoomNameKey(roomId));
    if (typeof roomName === 'string') {
        return loaded(roomName);
    }

    const arcadeId = getArcadeId(state);
    return arcadeId &&
        mapData(state.games.roomListsByArcade[arcadeId], roomList => roomList.find(room => room.id === roomId)?.name || 'Room') ||
        NOT_LOADED;
};

export const isRoomInstantPlay = (state: GamesAppState, roomId: string): boolean =>
    state.games.roomsById[roomId]?.freePlay ?? false;

export const roomHasCabinets = (state: GamesAppState, roomId: string): boolean =>
    state.games.roomsById[roomId]?.cabinets.length > 0;

export const getCabinetById = (state: GamesAppState, cabinetId: string): CabinetView | undefined =>
    state.games.cabinetsById[cabinetId];

/**
 * Stub in case games ever cost more than one token
 */
// noinspection JSUnusedLocalSymbols
export const getCabinetCost = (state: GamesAppState, cabinetId: string, roomId: string) => 1;

export function getCabinetContent<F>(
    state: TranslationsAppState,
    contentType: CabinetContentType,
    cabinetId: string,
    romId: string,
    fallback: F,
): string | F {
    const { cabinet: formatCabinetKey, rom: formatRomKey } = CABINET_CONTENT_KEY_FORMATTERS[contentType];

    const cabinetContent = Translations.getTranslation(state, formatCabinetKey(cabinetId));
    if (typeof cabinetContent === 'string') {
        return cabinetContent;
    }

    const romContent = Translations.getTranslation(state, formatRomKey(romId));
    if (typeof romContent === 'string') {
        return romContent;
    }

    return fallback;
}

export const getAutoPlayPreferenceEnabled = (state: GamesAppState) =>
    mapData(state.games.arcadeList, (arcadeList) =>
        arcadeList.some((arcade) =>
            arcade.autoplayPreferenceEnabled !== false,
        ),
    );

export const shouldHideInstantPlays = (state: GamesAppState) =>
    mapData(state.games.arcadeList, (arcadeList) =>
        arcadeList.every((arcade) =>
            arcade.hideInstantPlays === true,
        ),
    );

export const shouldHideArcadeFloorLink = (state: GamesAppState) =>
    mapData(state.games.arcadeList, (arcadeList) =>
        arcadeList.every((arcade) =>
            arcade.hideArcadeFloorLink === true,
        ),
    );
