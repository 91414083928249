import React from 'react';
import PropTypes from 'prop-types';
import { Radio as AntRadio } from 'antd';
import Button from './button';
import Card from './card';
import Group from './group';


const Radio = ( { ...props } ) => {
    return (
        <AntRadio
            className={ props.status ? `origami-radio-${ props.status }` : '' }
            { ...props }
        />
    );
};

Radio.Group = Group;
Radio.Button = Button;
Radio.Card = Card;

Radio.propTypes = {
    /** error status */
    status: PropTypes.oneOf( [ 'error', undefined ] )
};

export { Radio };