import { Session, SessionAppState } from '../session';
import { DispatchFor } from '../../util/createReducer';
import { dataOrThrow } from '../../util/Loadable';
import { Arcade } from '../../api';
import { tokens as tokenReducer } from './reducer';
import { getTokens } from './selectors';
import { Token, TokensAppState } from './types';

export type TokensDispatch = DispatchFor<typeof tokenReducer>;

export const loadTokens = () =>
    async (dispatch: TokensDispatch, getState: () => SessionAppState & TokensAppState) => {
        const client = Session.getAxios(getState());
        const personId = Session.getPersonId(getState());

        dispatch({ type: 'TOKENS_LOADING' });
        try {
            const { tokens } = await Arcade.getTokens(client, personId);
            dispatch({ type: 'TOKENS_LOADED', payload: tokens });
            return dataOrThrow(getTokens(getState()));
        } catch (error) {
            dispatch({ type: 'TOKENS_ERROR', payload: error });
            throw error;
        }
    };

export const updateTokens = (tokens: Token[]) => ({
    type: 'TOKENS_UPDATED' as 'TOKENS_UPDATED', // prevent Typescript from turning this into a `type: string`
    payload: tokens,
});
