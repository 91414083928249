import { message as antdMessage } from 'antd';
import React, { useState } from 'react';
import { DangerFilled, InfoFilled, CircleCheckFilled, ErrorFilled, Lightbulb, Spinner, CloseLarge } from '@nackle/origami-icons';
import { Button } from '../../exports';


const getIcon = ( type ) => {
    switch ( type ) {
        case 'info':
            return <InfoFilled className="ant-message-info-icon ant-message-icon"/>;
        case 'success':
            return <CircleCheckFilled className="ant-message-success-icon ant-message-icon"/>;
        case 'warning':
            return <DangerFilled className="ant-message-warning-icon ant-message-icon"/>;
        case 'error': 
            return <ErrorFilled className="ant-message-error-icon ant-message-icon"/>;
        case 'tip':
            return <Lightbulb className="ant-message-tip-icon ant-message-icon"/>;
        case 'loading':
            return <Spinner className="ant-message-loading-icon ant-message-icon"/>;
        default:
            return;
    }
};


export const message =  {
    // static methods
    open: function( config ) {
        antdMessage.open( {
            ...config,
            className: config.size === 'large' ? 'ant-message-large' : config.className,
            type: config.type === 'tip' ? 'info' : config.type, // antd doesn't have a tip type, overwrite what we pass to it but use custom icon
            icon: getIcon( config.type ),
            duration: config.duration,
            content: <>
                <div>{ config.content }</div>
                { /* Use closable because it's consistent with other antd parameters,  will add a close icon for messages that doesn't do anything for some reason and is not 
                a part of the documentation, we display: none that icon in the less and use our own. */ }
                { config.closable && <Button size="small" type='link' shape='circle' icon={ <CloseLarge className={ 'ant-message-close-icon' }/> } onClick={ ()=> {
                        // destroy for static methods doesn't work for specific key, this will close all messages
                        // no way i know of to close specific message with static messages. 
                        antdMessage.destroy();
                    } }/> } 
                
            </>,
        } );
    },
    info: function ( content, duration, onClose ) {
        this.open( {
            content,
            duration,
            onClose,
            type: 'info'
        } );
    },
    success: function ( content, duration, onClose ) {
        this.open( {
            content,
            duration,
            onClose,
            type: 'success'
        } );
    },
    warning: function ( content, duration, onClose ) {
        this.open( {
            content,
            duration,
            onClose,
            type: 'warning'
        } );
    },
    error: function ( content, duration, onClose ) {
        this.open( {
            content,
            duration,
            onClose,
            type: 'error'
        } );
    },
    tip: function ( content, duration, onClose ) {
        this.open( {
            content,
            duration,
            onClose,
            type: 'tip'
        } );
    },
    loading: function ( content, duration, onClose ) {
        this.open( {
            content,
            duration,
            onClose,
            type: 'loading'
        } );
    },
    // Callback methods
    useMessage: () => {
        const [ messageApi, contextHolder ] = antdMessage.useMessage();

        const open = ( config ) => {
            const key = +new Date() + '';// need a unique key for each notification so the destroy() call works, thought about using the message content but no guarantee that it's unique.
            messageApi.open( {
                ...config,
                className: config.size === 'large' ? 'ant-message-large' : config.className,
                type: config.type === 'tip' ? 'info' : config.type, // antd doesn't have a tip type, overwrite what we pass to it but use custom icon
                icon: getIcon( config.type ),
                duration: config.duration,
                key,
                content: <>
                    <div>{ config.content }</div>
                    { /* Use closable because it's consistent with other antd parameters,  will add a close icon for messages that doesn't do anything for some reason and is not 
                    a part of the documentation, we display: none that icon in the less and use our own. */ }
                    { config.closable && <Button size="small" type='link' shape='circle' icon={ <CloseLarge className={ 'ant-message-close-icon' }/> } onClick={ ()=> {
                            messageApi.destroy( key );
                        } }/> } 
                    
                </>,
            } );
        };
        const info = ( content, duration, onClose ) => {
            open( {
                content,
                duration,
                onClose,
                type: 'info'
            } );
        };
        const success = ( content, duration, onClose ) => {
            open( {
                content,
                duration,
                onClose,
                type: 'success'
            } );
        };
        const warning = ( content, duration, onClose ) => {
            open( {
                content,
                duration,
                onClose,
                type: 'warning'
            } );
        };
        const error = ( content, duration, onClose ) => {
            open( {
                content,
                duration,
                onClose,
                type: 'error'
            } );
        };

        const tip = ( content, duration, onClose ) => {
            open( {
                content,
                duration,
                onClose,
                type: 'tip'
            } );
        };
        const loading = ( content, duration, onClose ) => {
            open( {
                content,
                duration,
                onClose,
                type: 'loading'
            } );
        };
        // we need to insure that the methods keep the same reference between rerenders so we set  as state variable.
        // unnecessary rerenders and side effects can happen otherwise.  
        const [ api ] = useState( { open, info, success, warning, error, tip, loading } );

        return [ api, contextHolder ];
    }
};

