/*
 * "Loadable" is a monad wrapping whether-or-not-a-thing-is-loaded.
 * This file contains their definitions as well as functions for helping deal with them.
 *
 * If you're familiar with Java 8's Optional monad, this is kinda like that.
 */

export interface BaseLoadable {
    isLoaded: boolean;
    isLoading: boolean;
    error: any;
}

export interface NotLoaded extends BaseLoadable {
    isLoaded: false;
}

export interface Loaded<T> extends BaseLoadable {
    isLoaded: true;
    data: T;
}

export type Loadable<T> = NotLoaded | Loaded<T>;

// Constants so builders can return the same reference when possible.
export const NOT_LOADED: NotLoaded = { isLoaded: false, isLoading: false, error: null };
const INITIAL_LOADING: NotLoaded = { isLoaded: false, isLoading: true, error: null };

// Builders

export const notLoaded = () => NOT_LOADED;

export const loading = <T>(previous?: NotLoaded | Loaded<T>) =>
    previous ?
        previous.isLoaded ?
            ({ isLoaded: true, isLoading: true, error: null, data: previous.data }) as Loaded<T> :
            INITIAL_LOADING :
        INITIAL_LOADING;

export const loaded = <T>(data: T) =>
    ({ isLoaded: true, isLoading: false, error: null, data }) as Loaded<T>;

export const errorLoading = <T>(error: any, previous: NotLoaded | Loaded<T> = notLoaded()) =>
    ({ isLoaded: false, isLoading: false, error }) as NotLoaded;

export function dataOrThrow<T>(loadable: Loadable<T>): T {
    if (loadable.isLoaded) {
        return loadable.data;
    }
    throw new Error('Data is not loaded');
}

export function dataOrElse<T>(loadable: Loadable<T>, supplier: (() => T) | T): T {
    return loadable.isLoaded ? loadable.data : (typeof supplier === 'function' ? (supplier as (() => T))() : supplier);
}

export async function dataOrElseAsync<T>(loadable: Loadable<T>, supplier: () => (T | Promise<T>)): Promise<T> {
    return loadable.isLoaded ? loadable.data : await supplier();
}

export function mapData<T, R>(loadable: Loadable<T>, mapping: (thing: T) => R): Loadable<R> {
    if (loadable.isLoaded) {
        return loaded(mapping(loadable.data));
    }
    return loadable;
}

export function flatMapData<T, R>(loadable: Loadable<T>, mapping: (thing: T) => Loadable<R>): Loadable<R> {
    if (loadable.isLoaded) {
        return mapping(loadable.data);
    }
    return loadable;
}

export function flattenIfAllLoaded<T>(listOfLoadables: Array<Loadable<T>>): Loadable<T[]> {
    if (listOfLoadables.every((thing: Loadable<T>) => thing.isLoaded)) {
        return loaded(listOfLoadables.map((thing: Loadable<T>) => (thing as Loaded<T>).data));
    }
    return errorLoading(listOfLoadables, notLoaded());
}
