/**
 * Build URLs and variables for resources
 */

export function getEnvironment() {
    if (/localhost/.test(window.location.hostname)) {
        return 'qa';
    }

    return window.location.hostname.toLowerCase().split('.').find((chunk) => {
        return ['qa', 'pprd'].indexOf(chunk) >= 0;
    } );
}

export function getDeployedResourceUrl() {
    const environment = getEnvironment();

    if (/localhost/.test(window.location.hostname)) {
        return 'http://localhost:4621';
    }

    // No environment = PROD
    if (!environment) {
        return 'https://arcade.biw.cloud/loader/index.html';
    }

    return `https://${environment}.arcade.biw.cloud/loader/index.html`;
}
