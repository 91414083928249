import React, { CSSProperties } from 'react';

const BASE_WIDTH = 75.892937;
const BASE_HEIGHT = 65.13504;
const SCALE = 2;

export const RobotIcon = ({ style }: { style: CSSProperties }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        version="1.1"
        viewBox="0 0 17.233646 20.080007"
        height={BASE_WIDTH * SCALE}
        width={BASE_HEIGHT * SCALE}
        style={style}
    >
        <g style={{ fill: 'none', stroke: '#666', strokeLinejoin: 'miter' }}>
            <path
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'square', strokeLinejoin: 'round' }}
                d="m 5.5919794,8.1737556 c 0.5291671,0 1.8520834,1.322916 3.7041676,1.8520834 l 0.66146,0.892969"
            />
            <path
                d="M 16.969063,19.815423 V 1.8237556 h -6.35 l 1.058333,2.116667 V 10.290424 L 7.444063,12.40709 v 7.408333 z"
                style={{ strokeWidth: 0.5291667, strokeLinecap: 'butt' }}
            />
            <path
                d="m 0.56489647,19.815423 1.32291673,-1.5875 h 1.5875 l 1.3229165,1.5875 z"
                style={{ strokeWidth: 0.5291667, strokeLinecap: 'butt' }}
            />
            <path
                d="m 1.8878132,18.227923 -1e-7,-4.7625 1.5874999,0.529167 2e-7,4.233333 z"
                style={{ strokeWidth: 0.5291667, strokeLinecap: 'butt' }}
            />
            <path
                d="M 5.0628127,10.290422 4.0044816,13.99459 1.193233,13.241318 2.836734,7.1076895 5.6479806,7.8609608 5.5919796,8.7029228"
                style={{ strokeWidth: 0.52899998, strokeLinecap: 'round' }}
            />
            <rect
                transform="rotate(15)"
                y="6.9089365"
                x="5.2182584"
                height="1.5875001"
                width="1.6004779"
                style={{ strokeWidth: 0.52916664, strokeLinecap: 'square' }}
            />
            <rect
                transform="rotate(15)"
                y="1.7535924"
                x="3.981853"
                height="4.2333331"
                width="4.2333331"
                style={{ strokeWidth: 0.52916664, strokeLinecap: 'square' }}
            />
            <path
                d="M 5.5371155,4.9426186 6.303819,5.1480566"
                style={{ strokeWidth: 0.5291667, strokeLinecap: 'butt' }}
            />
            <path
                d="M 6.4040796,6.8184216 4.8706724,6.4075466 4.4964951,5.7594516"
                style={{ strokeWidth: 0.5291667, strokeLinecap: 'butt' }}
            />
            <path
                d="M 5.4368548,3.2722526 5.8477301,1.7388456"
                style={{ strokeWidth: 0.5291667, strokeLinecap: 'butt' }}
            />
            <circle
                transform="rotate(15)"
                r="0.52916664"
                cy="-0.098492205"
                cx="6.0985198"
                style={{ strokeWidth: 0.52916664, strokeLinecap: 'square' }}
            />
            <path
                transform="rotate(15)"
                d="m 4.9528404,0.56296608 a 1.3229166,1.3229166 0 0 1 0,-1.3229166"
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'square' }}
            />
            <path
                transform="rotate(-165)"
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'square' }}
                d="m -7.2441992,0.75995049 a 1.3229166,1.3229166 0 0 1 0,-1.32291656"
            />
            <path
                transform="rotate(15)"
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'square' }}
                d="m 4.5110198,0.8180513 a 1.8330871,1.8330871 0 0 1 0,-1.833087"
            />
            <path
                d="m -7.6860198,1.0150357 a 1.8330871,1.8330871 0 0 1 0,-1.83308699"
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'square' }}
                transform="rotate(-165)"
            />
            <path
                d="m 4.7982294,8.4383397 c 0.5291671,-5e-7 1.8520834,1.3229157 3.704167,1.8520833 L 8.237815,11.613339 C 6.9148975,11.348758 5.5919812,10.555006 4.5336478,10.02584 Z"
                style={{ strokeWidth: 0.5291667, strokeLinecap: 'square', strokeLinejoin: 'round' }}
            />
            <path
                d="M 1.887813,17.698756 3.4753129,17.169589"
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'butt' }}
            />
            <path
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'butt' }}
                d="M 1.887813,16.640423 3.4753129,16.111256"
            />
            <path
                d="M 1.887813,15.58209 3.4753129,15.052923"
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'butt' }}
            />
            <path
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'butt' }}
                d="M 1.887813,14.523756 3.4753129,13.994589"
            />
            <path
                d="M 4.5336462,10.025839 5.5919796,8.9675056"
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'butt' }}
            />
            <path
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'butt' }}
                d="M 5.5919797,10.555006 6.6503131,9.4966726"
            />
            <path
                d="M 6.650313,10.819589 7.7086464,9.7612556"
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'butt' }}
            />
            <path
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'butt' }}
                d="M 7.444063,11.348756 8.502396,10.290423"
            />
            <path
                d="M 8.502396,10.290423 9.56073,11.348756 8.502396,11.877923 8.237813,11.613339 Z"
                style={{ strokeWidth: 0.5291667, strokeLinecap: 'butt', strokeLinejoin: 'round' }}
            />
            <path
                d="m 4.1367713,13.862298 v 3.96875 l 1.0583333,1.322917 h -0.79375"
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'butt' }}
            />
            <path
                d="M 4.2690629,17.831048 H 3.7398963"
                style={{ strokeWidth: 0.26458332, strokeLinecap: 'butt' }}
            />
        </g>
    </svg>
);
