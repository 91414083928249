import React from 'react';
import PropTypes from 'prop-types';

import classNames from 'classnames';
import './nackle-tile.less';

const NackleTileDoubleInfinity = ( { className, children } ) => {
    const tileClasses = classNames( {
        'nackle-tile': true,
        'nackle-tile-double-infinity': true,
        [ className ]: className ? true : false
    } );
    return (
        <div className={ tileClasses }>
            { children }
        </div>
    );
};
NackleTileDoubleInfinity.propTypes = {
    /** optional class name */
    className: PropTypes.string
};
export { NackleTileDoubleInfinity };