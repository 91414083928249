import React from 'react';
import PropTypes from 'prop-types';

import BridgeContext, { defaultState } from './context';

class Bridge extends React.Component {
    state = {
        ...defaultState,
        auth: this.props.auth,
    };

    static propTypes = {
        auth: PropTypes.object.isRequired,
        requiresAuth: PropTypes.bool,

        onSignInNeeded: PropTypes.func,
        onSignIn: PropTypes.func,
        onSignInError: PropTypes.func,
        onSignOut: PropTypes.func,
        onSignOutError: PropTypes.func,
        onAuthStateChange: PropTypes.func,
        onSetLocation: PropTypes.func,
        onSessionExpired: PropTypes.func,
        children: PropTypes.node,
        renderNotAuthenticated: PropTypes.func
    };

    static defaultProps = {
        requiresAuth: true
    };

    static getDerivedStateFromProps( props, state ) {
        if ( props.requiresAuth !== state.requiresAuth ) {
            const requiresAuth = typeof state.requiresAuth !== 'boolean' ? props.requiresAuth : state.requiresAuth;
            const auth = state.auth || props.auth;

            if ( !state.signedIn ) {
                Bridge.startOrContinueFlow( { auth, requiresAuth } );
            }
            return {
                requiresAuth
            };
        }
        return null;
    }

    static startOrContinueFlow = async ( { auth, requiresAuth } ) => {
        if ( !auth ) {
            console.error( 'auth prop is required to sign in' );
            return;
        }
        // if we are at a auth url, resume sign in
        if ( Bridge.isAuthUrl( auth ) ) {
            return await auth.resume();
        }
        // if there is an sso token, try to sign in with that
        const ssoToken = auth.getSSOToken();
        if ( ssoToken ) {
            return await auth.signIn();
        }
        // if there is already a session, resume sign in
        const session = await auth.getSession();
        if ( session ) {
            return await auth.resume( session, requiresAuth );
        }
        // if we require auth, start sign in
        if ( requiresAuth ) {
            return await auth.signIn();
        }
    }

    static isAuthUrl = ( auth ) => {
        return ( auth && auth.isAuthUri( auth.getCurrentUri() ) );
    }

    static hasSSOToken = ( auth ) => {
        return ( auth && auth.hasSSOToken() );
    }

    setRequiresAuth = requiresAuth => {
        this.setState( { requiresAuth } );
    }

    componentDidMount() {
        const {
            auth,
        } = this.state;
        const {
            onSignInNeeded,
            onAuthStateChange,
            onSetLocation,
            onSignInError,
            onSignOutError,
            onSessionExpired,
            onSessionExpiring
        } = this.props;

        if ( !auth ) {
            return;
        }
        auth.events.signInNeeded.on( onSignInNeeded );
        auth.events.signIn.on( this.handleSignIn );
        auth.events.signOut.on( this.handleSignOut );
        auth.events.stateChange.on( onAuthStateChange );
        auth.events.setLocation.on( onSetLocation );
        auth.events.signInError.on( onSignInError );
        auth.events.signOutError.on( onSignOutError );
        auth.events.sessionExpired.on( onSessionExpired );
        auth.events.sessionExpiring.on( onSessionExpiring );
    }

    componentWillUnmount() {
        const {
            auth,
        } = this.state;
        const {
            onSignInNeeded,
            onAuthStateChange,
            onSetLocation,
            onSignInError,
            onSignOutError,
            onSessionExpired,
            onSessionExpiring
        } = this.props;

        if ( !auth ) {
            return;
        }

        auth.events.signInNeeded.off( onSignInNeeded );
        auth.events.signIn.off( this.handleSignIn );
        auth.events.signOut.off( this.handleSignOut );
        auth.events.stateChange.off( onAuthStateChange );
        auth.events.setLocation.off( onSetLocation );
        auth.events.signInError.off( onSignInError );
        auth.events.signOutError.off( onSignOutError );
        auth.events.sessionExpired.off( onSessionExpired );
        auth.events.sessionExpiring.off( onSessionExpiring );
    }

    render() {
        const { children } = this.props;
        const { auth, requiresAuth, signedIn } = this.state;

        // if it's auth url, don't render children
        if ( Bridge.isAuthUrl( auth ) ) {
            return this.renderNotAuthenticated();
        }
        // if auth is required and we are not signed in, don't render children
        if ( requiresAuth && !signedIn ) {
            return this.renderNotAuthenticated();
        }

        // otherwise, the user is signed in OR auth is not requiresd so render children
        return (
            <BridgeContext.Provider
                value={ {
                    auth,
                    requiresAuth,
                    signedIn,
                    setRequiresAuth: this.setRequiresAuth,
                } }
            >
                { children }
            </BridgeContext.Provider>
        );
    }

    renderNotAuthenticated( loading ) {
        const { renderNotAuthenticated } = this.props;

        if ( typeof renderNotAuthenticated === 'function' ) {
            return renderNotAuthenticated( loading );
        }

        return null;
    }

    handleSignIn = ( user ) => {
        const { onSignIn } = this.props;
        const { signedIn } = this.state;

        if ( signedIn ) {
            return;
        }
        this.setState( { signedIn: true }, () => {
            if ( typeof onSignIn === 'function' ) {
                onSignIn( user );
            }
        } );
    }

    handleSignOut = () => {
        const { onSignOut } = this.props;
        const { signedIn } = this.state;

        if ( !signedIn ) {
            return;
        }
        this.setState( { signedIn: false }, () => {
            if ( typeof onSignOut === 'function' ) {
                onSignOut();
            }
        } );
    }
}

export default Bridge;
