import React from 'react';
import PropTypes from 'prop-types';
import { Spin as AntSpin } from 'antd';
import { Spinner } from '@nackle/origami-icons';
import classNames from 'classnames';
const Spin = ( { ...props } ) => {
    const { size, children } = props;
    const spinClass = ( size ) => {
        switch ( size ) {
            case 'small':
                return 'nackle-spin-small';
            case 'large':
                return 'nackle-spin-large';
            case 'x-large':
                return 'nackle-spin-extra-large';
            default:
                return 'nackle-spin-default';
        }
    };
    const nackleClass = spinClass( size );
    const spinClasses = classNames( {
        [ props.className ]: props.className ? true : false,
        [ nackleClass ]: true
    } );
    return (
        <AntSpin 
            indicator={ <Spinner className="primary-color" /> } 
            { ...props } 
            className={ spinClasses } >{ children }</AntSpin>
    );
};

Spin.propTypes = {
    /** size of spinner */
    size: PropTypes.oneOf( [ 'small', 'large', 'x-large', 'default' ] ),
    /** class name for spinner */
    className: PropTypes.string
};
export { Spin };