import React from 'react';
import { Checkbox as Chbx } from 'antd';


const Checkbox = ( { children, ...props } ) => {
    return (
        <Chbx { ...props }>{ children }</Chbx>
    );
};

const Group = ( { ...props } ) => (
    <Chbx.Group { ...props } />
);

Checkbox.Group = Group;

export { Checkbox };
