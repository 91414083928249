import unionBy from 'lodash/unionBy';
import { TokenState } from '../../api/arcade';
import { createReducer } from '../../util/createReducer';
import { errorLoading, loaded, loading, mapData, NOT_LOADED } from '../../util/Loadable';
import { Token, TokensState } from './types';

const initialState: TokensState = {
    tokenList: NOT_LOADED,
};

const reducers = {
    TOKENS_LOADING: (state: TokensState) => ({
        ...state,
        tokenList: loading(state.tokenList),
    }),
    TOKENS_LOADED: (state: TokensState, tokenList: Token[]) => ({
        ...state,
        tokenList: loaded(tokenList),
    }),
    TOKENS_ERROR: (state: TokensState, error: any) => ({
        ...state,
        tokenList: errorLoading(error, state.tokenList),
    }),
    TOKENS_UPDATED: (state: TokensState, updatedTokens: Token[]) => ({
        ...state,
        tokenList: mapData(state.tokenList, (existingTokens) =>
            // The set-union of elements of N arrays is generated using the key returned by the callback method.
            // When two or more arrays contain an "equal" element, the earlier array's element is returned.
            unionBy(updatedTokens, existingTokens, (token: Token) => token.id),
        ),
    }),

    // Mark all available tokens as used.
    PREFERENCES_AUTO_PLAY_UPDATED: (state: TokensState, setting: boolean) =>
        (setting) ?
            ({
                ...state,
                tokenList: mapData(state.tokenList, (existingTokens) =>
                    existingTokens.map((token) =>
                        token.state === TokenState.AVAILABLE ?
                            { ...token, state: TokenState.USED } :
                            token,
                    ),
                ),
            }) :
            state,
};

export const tokens = createReducer(initialState, reducers);
