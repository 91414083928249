// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/**
 * Creates a grid of uniformly-wide tiles
 *
 * IE 11 doesn't support key grid features like auto-placement,
 * so we have to make do with flexbox.
 *
 * Using this requires you to create N empty <divs> inside your container,
 * where N is the maximum number of tiles you expect to see in a given row.
 * These extra divs will prevent the last row of *real* tiles from growing
 * to fill the entire width (which breaks the vertical alignment of the tile
 * edges) by horizontally growing at the same rate as the real tiles. Any
 * excess tiles wrap to the next line, but because they have zero height they
 * won't take up any space.
 *
 * @param \$minTileWidth - Minimum tile size before they wrap
 * @param \$tileSpacing - Spacing between the tiles
 * @param \$spacerClassName - Class name of the spacer tile
 */
.instant-play-list {
  display: flex;
  flex-wrap: wrap;
  margin: -10px;
}
.instant-play-list > * {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: 0;
  margin-left: 10px;
  margin-right: 10px;
  min-width: 266.66666667px;
}
.instant-play-list > *:not(.instant-play-spacer) {
  margin-top: 10px;
  margin-bottom: 10px;
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
