import { PreloadImage } from '@nackle/arcade-core';
import { Spin } from '@nackle/paper';
import React, { Component } from 'react';

export class GameImage extends Component<{ url?: string, altText?: string }, { imageLoaded?: boolean }> {
    public state: { imageLoaded?: boolean } = {};

    public render() {
        const {
            url,
            altText
        } = this.props;
        const { imageLoaded } = this.state;

        return (
            <div className="game-cover-image-container">
                <PreloadImage
                    onPreloadComplete={this.handleImagePreloaded}
                    url={url}
                    alt={altText}
                />

                {!imageLoaded && <Spin className="game-cover-image-spinner" />}

                {imageLoaded &&
                <div
                    className="game-cover-image"
                    style={{
                        backgroundImage: `url(${url})`,
                    }}
                />
                }
            </div>
        );
    }

    private handleImagePreloaded = () => this.setState({ imageLoaded: true });
}
