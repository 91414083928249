import { CMX } from '../../api';
import { getAxios } from '../session/selectors';
import { AppState } from '../store';
import { DispatchFor } from '../../util/createReducer';
import { translations } from './reducer';

export type TranslationsDispatch = DispatchFor<typeof translations>;

export const loadAllTranslations = (projectName: string, localeCode: string) => async (dispatch: TranslationsDispatch, getState: () => AppState) => {
    const client = getAxios(getState());

    const response = await CMX.listAllTranslatedProjectStrings(client, projectName, localeCode);

    const payload: { [key: string]: string } = {};
    for (const translation of response.strings) {
        payload[translation.key] = translation.value;
    }

    dispatch({ type: 'TRANSLATIONS_LOADED', payload });
};

export const loadTranslations = (projectName: string, localeCode: string, keys?: string[]) =>
    async (dispatch: TranslationsDispatch, getState: () => AppState) => {
        const client = getAxios(getState());

        const response = await CMX.getTranslatedProjectStrings(client, projectName, localeCode, keys);

        const localeBundle = response.bundles[localeCode];
        if (localeBundle) {
            dispatch({ type: 'TRANSLATIONS_LOADED', payload: localeBundle });
        } else {
            throw new Error(`Translation bundle for locale ${localeCode} was not returned. This was unexpected.`);
        }
    };
