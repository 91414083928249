// libs
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import formatNumber from './format-number';
import { CURRENT_LOCALE_CODE, languageChangeEvent } from '../translations';

const propTypes = {
    value: PropTypes.oneOfType( [
        PropTypes.string,
        PropTypes.number
    ] ),
    TextComponent: PropTypes.any,
    formatOptions: PropTypes.shape( {} )
};

const isValid = ( value ) => typeof value === 'number' || typeof value === 'string';

class FormattedNumber extends Component {
    componentDidMount() {
        languageChangeEvent.on( this.onLanguageChange );
    }

    componentWillUnmount() {
        languageChangeEvent.off( this.onLanguageChange );
    }

    onLanguageChange = () => this.forceUpdate();

    render() {
        const {
            value,
            formatOptions = {},
            TextComponent = 'span',
            ...props
        } = this.props;

        const locale = CURRENT_LOCALE_CODE;

        return <TextComponent { ...props }>{ isValid( value ) && formatNumber( locale, formatOptions, value ) }</TextComponent>;
    }
}

FormattedNumber.propTypes = propTypes;
export default FormattedNumber;
