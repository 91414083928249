import React from 'react';
import { Select as AntSelect } from 'antd';
import { ChevronDown, Close, Check, Spinner } from '@nackle/origami-icons';
const Select = ( { ...props } ) => {
    const { size, loading } = props;

    switch ( size ) {
        case 'small':
            return (
                <AntSelect
                    removeIcon={ <Close width='12' height='12' fill='currentColor' /> }
                    { ...props }
                    suffixIcon={ loading ? <Spinner width='16' height='16' fill='currentColor'  /> : <ChevronDown width='16' height='16' fill='currentColor' /> }
                    menuItemSelectedIcon={ <Check width='14' height='14' fill='currentColor' /> }
                >
                </AntSelect>
            );
        case 'medium':
            return (
                <AntSelect
                    removeIcon={ <Close width='14' height='14' fill='currentColor' /> }
                    { ...props }
                    suffixIcon={ loading ? <Spinner width='20' height='20' fill='currentColor' /> : <ChevronDown width='20' height='20' fill='currentColor' /> }
                    menuItemSelectedIcon={ <Check width='14' height='14' fill='currentColor' /> }
                >
                </AntSelect>
            );
        case 'large':
            return (
                <AntSelect
                    removeIcon={ <Close width='16' height='16' fill='currentColor' /> }
                    { ...props }
                    suffixIcon={ loading ? <Spinner width='24' height='24' fill='currentColor' /> : <ChevronDown width='24' height='24' fill='currentColor' /> }
                    menuItemSelectedIcon={ <Check width='16' height='16' fill='currentColor' /> }
                >
                </AntSelect>
            );
        default:
            return (
                <AntSelect
                    removeIcon={ <Close width='14' height='14' fill='currentColor' /> }
                    { ...props }
                    suffixIcon={ loading ? <Spinner width='20' height='20' fill='currentColor' /> : <ChevronDown width='20' height='20' fill='currentColor' /> }
                    menuItemSelectedIcon={ <Check width='14' height='14' fill='currentColor' /> }
                >
                </AntSelect>

            );
    }
};


export { Select };
