import React from 'react';
import { Divider as Dividr } from 'antd';


export const Divider = ( { ...props } ) => {
    return (
        <Dividr
            { ...props }>

        </Dividr>
    );
};
