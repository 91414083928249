import parseDate from 'date-fns/parse';
import memoize from 'lodash/memoize';
import { resolveLocaleCode } from '../utils/resolve-locale-code';

const formatterOpts = {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
};
const getFormatter = memoize( ( code ) => Intl.DateTimeFormat( code, formatterOpts ) );

export default function formatDate( locale, value ) {
    let code = resolveLocaleCode( locale );

    try {
        Intl.DateTimeFormat.supportedLocalesOf( [ code ] );
    } catch ( error ) {
        code = 'en-US';
    }

    const dateTime = value instanceof Date ? value : parseDate( value );
    const formatter = getFormatter( code );
    return formatter.format( dateTime );
}
