import { AxiosInstance } from 'axios';
import { AutoPlayPreferenceRequest, AutoPlayPreferenceResponse, PreferencesResponse } from './types';

export const TOKEN_SERVICE_ROOT = '/token/v1'; // visible for testing

function defaultPreferences(companyId: string, personId: string): PreferencesResponse {
    return {
        companyId,
        personId,
        autoplayEnabled: false,
    };
}

export const loadPreferences = async (
    client: AxiosInstance,
    companyId: string,
    personId: string,
): Promise<PreferencesResponse> => {
    try {
        const url = `${TOKEN_SERVICE_ROOT}/person/${personId}/preferences`;
        return (await client.get<PreferencesResponse>(url)).data;
    } catch (error: any) {
        // API quirk: If the API doesn't know about a user, it throws a 404.
        console.log('the error is', JSON.stringify(error));
        if ( error?.response?.status === 404) {
            console.log('inside 404');
            return defaultPreferences(companyId, personId);
        }
        throw new Error(error);
    }
};

export const updateAutoPlayPreference = async (
    client: AxiosInstance,
    personId: string,
    setting: boolean,
) => {
    const payload: AutoPlayPreferenceRequest = { autoplayEnabled: setting };
    const url = `${TOKEN_SERVICE_ROOT}/person/${personId}/preferences/autoplay`;
    return (await client.post<AutoPlayPreferenceResponse>(url, payload)).data;
};
