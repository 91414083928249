import { TokenService } from '../../api';
import { Session, SessionAppState } from '../session';
import { TokensDispatch } from '../tokens';
import { PlayDispatch } from './reducer';
import { PreferencesAppState } from './types';

export const loadPreferences = () =>
    async (dispatch: TokensDispatch & PlayDispatch, getState: () => PreferencesAppState & SessionAppState) => {
        const state = getState();
        const client = Session.getAxios(state);
        const companyId = Session.getCompanyId(state);
        const personId = Session.getPersonId(state);

        dispatch({ type: 'PREFERENCES_LOADING' });
        try {
            const preferences = await TokenService.loadPreferences(client, companyId, personId);

            dispatch({ type: 'PREFERENCES_AUTO_PLAY_LOADED', payload: preferences.autoplayEnabled });

        } catch (error) {
            dispatch({ type: 'PREFERENCES_LOADING_ERROR', payload: error });
            throw error;
        }
    };

export const setAutoPlayPreference = (setting: boolean) =>
    async (dispatch: TokensDispatch & PlayDispatch, getState: () => PreferencesAppState & SessionAppState) => {
        const state = getState();
        const client = Session.getAxios(state);
        const personId = Session.getPersonId(state);

        dispatch({ type: 'PREFERENCES_AUTO_PLAY_UPDATING' });
        try {
            const preferences = await TokenService.updateAutoPlayPreference(client, personId, setting);

            dispatch({ type: 'PREFERENCES_AUTO_PLAY_UPDATED', payload: preferences.autoplayEnabled });
        } catch (error) {
            dispatch({ type: 'PREFERENCES_AUTO_PLAY_UPDATE_ERROR', payload: error });
            throw error;
        }
    };
