import { getEnvironment } from 'utils/buildUrls';
import { getLocationOrigin } from 'utils/utils';
import { Auth } from '@nackle/bridge';

const ENV = getEnvironment() || 'prod';
const isProd = ENV === 'prod';
const envPrefix = isProd ? '' : `${ENV}.`;
const KONG_URL = `https://${envPrefix}api.biw.cloud`;

const CONFIG = {
    ENV,
    COMPANY_CODE: COMPANY_CODE || getLocationOrigin(),
    API_URL: KONG_URL,
    PROVIDER_URL: `${KONG_URL}/v1/id`,
};

export const auth = new Auth( {
    authority: CONFIG.PROVIDER_URL,
    clientId: CONFIG.COMPANY_CODE,
} );

export default CONFIG;
