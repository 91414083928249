import { AppState, CabinetView, dataOrElse, Games, ReduxProps, RoomView, Tokens, Translate } from '@nackle/arcade-core';
import { Typography } from '@nackle/paper';
import { StarCircleIcon } from 'components/icons';
import React from 'react';
import { connect } from 'react-redux';
import { defaultMemoize } from 'reselect';
import CabinetItem from './CabinetItem';
import { FloorText } from '../../translation-data';
import './RoomCabinetList.less';

const { Title } = Typography;
interface Props {
    onClickCabinet: (roomId: string, cabinetId: string) => void;
    room: RoomView;
}

const mapStateToProps = () => {
    const getCabinetList = defaultMemoize((state: AppState, room: RoomView) => {
        return room.cabinets
            .map((cabinet) => Games.getCabinetById(state, cabinet.cabinetId))
            .filter((cabinet) => Boolean(cabinet)) as CabinetView[];
    });

    return (state: any, props: Props) => {
        return {
            cabinets: getCabinetList(state, props.room),
            name: dataOrElse(Games.getRoomName(state, props.room.id), ''),
            roomTokenCount: dataOrElse(Tokens.countAvailableTokensForRoom(state, props.room.id), 0) || 0,
        };
    };
};

class RoomCabinetList extends React.Component<ReduxProps<Props, typeof mapStateToProps>> {
    public render() {
        const { cabinets, name, room, roomTokenCount } = this.props;

        return (
            <div className="room">
                <div className="room-header">
                    <div className="room-header-left">
                        <Title level={2}>{name}</Title>
                    </div>
                    <div className="room-header-right">
                        <StarCircleIcon className="tokensIcon" />&nbsp;<Translate id={FloorText.CREDITS_LABEL} />&nbsp;{roomTokenCount}
                    </div>
                </div>
                <div className="room-cabinet-list">
                    {cabinets.map((cabinet) => (
                        <CabinetItem
                            cabinet={cabinet}
                            key={cabinet.cabinetId}
                            roomId={room.id}
                            tokenCount={roomTokenCount}
                        />
                    ))}
                    <div className="room-cabinet-list-spacer" />
                    <div className="room-cabinet-list-spacer" />
                    <div className="room-cabinet-list-spacer" />
                    <div className="room-cabinet-list-spacer" />
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(RoomCabinetList);
