export default class Events {
    constructor() {
        this.listeners = [];
    }

    on = ( callback ) => {
        if ( typeof callback  === 'function' ) {
            this.listeners.push( callback );
        }
    }

    off = ( callback ) => {
        const index = this.listeners.indexOf( callback );
        if ( index >= 0 ) {
            this.listeners.splice( index, 1 );
        }
    }

    fire = async ( data ) => {
        for ( let i = 0; i < this.listeners.length;i++ ) {
            const listener = this.listeners[ i ];
            await listener( data );
        }
    }
}
