import {
    PreloadImage,
    Translations,
    Games,
    Promotion,
    AppState,
    ReduxProps
} from '@nackle/arcade-core';
import React, { Component, ReactNode } from 'react';
import { connect } from 'react-redux';
import './Hero.less';
// Keep in sync with definition in Hero.scss
const HERO_FADEIN_MS = 500;

interface HeroProps {
    backgroundImageUrl?: string;
    children?: ReactNode | ReactNode[];
}

interface State {
    fadeInImageUrl?: string;
    previousBackgroundImageUrl?: string;
}

const mapStateToProps = (state: AppState) => {
    const maybePromotedGame = Promotion.getPromotedGame(state);
    // @ts-ignore
    const promotedGame: any = maybePromotedGame.data;

    if (!promotedGame) {
        return {
            altText: Translations.getTranslation(
                state,
                'arcade.banner.no-tokens.alt',
            )
        }
    } else {
        const altTextTranslation = Translations.getTranslation(
            state,
            Games.cmxKeys.formatCmxCabinetBannerAltKey(promotedGame.cabinetId),
        );

        return {
            // if cabinet banner alt text does not exist, use rom banner alt text.
            altText: altTextTranslation ? altTextTranslation : Translations.getTranslation(
                state,
                Games.cmxKeys.formatCmxRomBannerAltKey(promotedGame.romId),
            )
        };
    }
};

class Hero extends Component<ReduxProps<HeroProps, typeof mapStateToProps>, State> {
    public state: State = {};

    public UNSAFE_componentWillReceiveProps(nextProps: Readonly<HeroProps>, nextContext: any): void {
        this.handleFadeinComplete();
    }

    public render() {
        const {
            backgroundImageUrl,
            children,
            altText
        } = this.props;
        const { fadeInImageUrl, previousBackgroundImageUrl } = this.state;

        return (
            <div
                className="hero"
                style={{ backgroundImage: `url(${previousBackgroundImageUrl})` }}
            >
                { backgroundImageUrl &&
                    <PreloadImage
                        onPreloadComplete={this.handleImageLoaded}
                        url={backgroundImageUrl}
                        alt={altText}
                    />
                }

                { fadeInImageUrl &&
                    <div
                        className="hero-background-fadein"
                        key={fadeInImageUrl}
                        style={{ backgroundImage: `url(${fadeInImageUrl}` }}
                    />
                }

                <div className="hero-content">
                    {children}
                </div>
            </div>
        );
    }

    private handleImageLoaded = (loadedImage: string) => {
        this.setState({ fadeInImageUrl: loadedImage });
        setTimeout(this.handleFadeinComplete, HERO_FADEIN_MS);
    }

    private handleFadeinComplete = () => {
        this.setState({
            fadeInImageUrl: undefined,
            previousBackgroundImageUrl: this.props.backgroundImageUrl,
        });
    }
}

export default connect( mapStateToProps )(Hero);
