import { Card } from '@nackle/paper';
import React, { Component, ReactNode } from 'react';

interface Props {
    description: ReactNode;
    header: ReactNode;
    playButton?: ReactNode;
    preHeader?: ReactNode;
}

export class GameBannerContent extends Component<Props> {
    public render() {
        const {
            description,
            header,
            playButton,
            preHeader,
        } = this.props;

        return (
            <Card className="nkl-dark game-banner-content">
                <div className="game-banner-info">
                    {preHeader && <div>{preHeader}</div>}
                    <div className="game-banner-game-name">
                        {header}
                    </div>
                    <div className="game-banner-game-description">
                        {description}
                    </div>
                </div>
                {playButton}
            </Card>
        );
    }
}
