import { Loadable } from '../..';
import { Arcade } from '../../api';

export type Token = Arcade.Token;
export const TokenState = Arcade.TokenState;

export interface TokensState {
    tokenList: Loadable<Token[]>;
}

export interface TokensAppState {
    tokens: TokensState;
}
