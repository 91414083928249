import React, { Component } from 'react';

interface Props {
    onPreloadComplete?: (loadedUrl: string) => void;
    url?: string;
    alt?: string;
}

interface State {
    loadedUrl?: string;
}

/**
 * Non-visible component to pre-load images offscreen.
 *
 * Use the `onPreloadComplete` callback to render content when the image has been loaded.
 *
 * Pre-loading images allows them to be ready in the browser's cache as soon as they're needed.
 * Images that aren't pre-loaded appear suddenly, some time after the view has already been rendered,
 * making the page appear jerky.
 *
 * If images are already pre-loaded, you can smoothly animate them in.
 *
 * This creates an invisible <img> tag and uses the "onload" event callback to detect that it's been loaded.
 */
export class PreloadImage extends Component<Props, State> {
    public state: State = {
        loadedUrl: undefined,
    };

    public render() {
        const {
            url,
            alt,
        } = this.props;

        return (
            <>
                {url &&
                    <img
                        className="image-preload"
                        height={1}
                        key={url}
                        onLoad={this.handleImageLoaded}
                        src={url}
                        style={{
                            position: 'absolute',
                            visibility: 'hidden',
                        }}
                        width={1}
                        alt={alt ? alt : '' }
                    />
                }
            </>
        );
    }

    private handleImageLoaded = () => {
        if (this.props.url === undefined) {
            return;
        }

        this.setState({ loadedUrl: this.props.url });

        if (this.props.onPreloadComplete) {
            this.props.onPreloadComplete(this.props.url);
        }
    }
}
