import throttle from 'lodash.throttle';
import Storage, { STORAGE_TYPE_LOCAL } from './storage';

const THROTTLE_TIME = 1000; // 1 second;

export default function( options = {} ) {
    const storage = new Storage( STORAGE_TYPE_LOCAL );
    let intervalHandle = null;

    function createCheck( name, timeoutInMinutes ) {
        const check = {
            name,
            storageKey: `bridge.${ name }.name`,
            timeoutInMinutes,
            timeoutInMilliseconds: ( timeoutInMinutes * 60000 ),
            isStoped: false
        };
        check.stop = () => {
            check.isStoped = true;
        };
        check.resume = () => {
            check.isStoped = false;
        };
        check.clear = () => {
            storage.removeItem( check.storageKey );
        };
        check.record = () => {
            const timeoutAt = new Date(
                new Date().getTime() + check.timeoutInMilliseconds
            );
            // set the new time out at
            storage.setItem( check.storageKey, timeoutAt.toISOString() );
        };
        check.run = () => {
            if ( check.isStoped ) {
                return false;
            }
            // get the timeout at time
            const timeoutAt = storage.getItem( check.storageKey );
            // check if now is beyond the timeout at
            if ( timeoutAt && new Date() >= new Date( timeoutAt ) ) {
                check.stop();
                return true;
            }
            return false;
        };

        return check;
    }

    const promptCheck = createCheck( 'prompt', options.activityPromptInMinutes || 2.5 );
    const signOutCheck = createCheck( 'signOut', options.activityTimeoutInMinutes || 5 );

    function recordActivity() {
        if ( !promptCheck.isStoped && !signOutCheck.isStoped ) {
            promptCheck.record();
            signOutCheck.record();
        }
    }

    const throttledRecordActivity = throttle(
        recordActivity,
        THROTTLE_TIME
    );

    function startChecking( promptCallback, signOutCallback ) {
        // stop activity tracking in case it is already happening
        stopChecking();
        // record activity to set an initial value;
        recordActivity();

        // add an interval to check that the activity timeout has not been exceeded
        intervalHandle = window.setInterval( () => {
            if ( promptCheck.run() ) {
                promptCheck.stop();
                if ( typeof promptCallback === 'function' ) {
                    promptCallback( {
                        timeoutAt: new Date( storage.getItem( signOutCheck.storageKey ) ),
                        continue: () => {
                            recordActivity();
                            promptCheck.resume();
                        },
                        signOut: () => {
                            if ( typeof signOutCallback === 'function' ) {
                                signOutCallback( true );
                            }
                        }
                     } );
                }
            }
            if ( signOutCheck.run() ) {
                signOutCheck.stop();
                if ( typeof signOutCallback === 'function' ) {
                    signOutCallback();
                }
            }
        }, THROTTLE_TIME );
        // add mouse move event handler to track activity
        window.addEventListener( 'mousemove', throttledRecordActivity );
    }
    function stopChecking() {
        // clear storage value
        promptCheck.clear();
        signOutCheck.clear();
        // clear timeout
        if ( intervalHandle ) {
            window.clearInterval( intervalHandle );
            intervalHandle = null;
            // remove mouse move event handler
            window.removeEventListener( 'mousemove', throttledRecordActivity );
        }
    }

    return {
        checkForTimeout: signOutCheck.run,
        startChecking,
        stopChecking,
        recordActivity
    };
}