import { CompanySetup, Solutions } from '../../api';
import { DispatchFor } from '../../util/createReducer';
import { Session, SessionAppState } from '../session';
import { links } from './reducer';
import { LinksAppState } from './types';
import { getEnvironment } from '../../util/environments';

export type LinksDispatch = DispatchFor<typeof links>;

const ARCADE_URL_PATTERN = /https:\/\/[^/]+\barcade\.biw\.cloud/;

/**
 * @param environment - The environment to build the URL for. Uses window URL to determine environment as a fallback
 * if not provided.
 */
export const loadArcadeUrl = (environment: any = undefined) =>
    async (dispatch: LinksDispatch, getState: () => LinksAppState & SessionAppState) => {
        const state = getState();
        const client = Session.getAxios(state);
        const companyId = Session.getCompanyId(state);

        dispatch({ type: 'ARCADE_URL_LOADING' });
        try {
            const identifiers = await CompanySetup.getCompanyIdentifiers(client, companyId);
            let url = identifiers.find((identifier) => Boolean(identifier.match(ARCADE_URL_PATTERN)));

            if (!url) {
                const solution = await Solutions.getSolutions(client);
                const experience = solution.experiences.find( (exp: any) => exp.name === 'Arcade');
                if (experience) {
                    let env = getEnvironment();

                    if (environment) {
                        env = environment;
                    }

                    url = `https://${solution?.solutionIdentity}${env ? `.${env}` : ''}.arcade.biw.cloud`;
                }
            }

            if (!url) {
                console.warn('Unable to determine URL of Arcade. Please add Arcade as an Experience in ' +
                'Solution Studio to enable the Arcade Floor link.');
                dispatch({ type: 'ARCADE_URL_LOADED', payload: null });
                return;
            }

            dispatch({ type: 'ARCADE_URL_LOADED', payload: url });
        } catch (error) {
            dispatch({ type: 'ARCADE_URL_ERROR', payload: error });
            throw error;
        }
    };
