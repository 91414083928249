import React from 'react';

export const Error = ( { className, ...props } ) => (
    <svg width="50" height="50" className={ className } { ...props } viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="50" height="50" rx="25" fill="#FAB6B6" />
        <rect x="5" y="5" width="40" height="40" rx="20" fill="white" />
        <path d="M25 15C19.49 15 15 19.49 15 25C15 30.51 19.49 35 25 35C30.51 35 35 30.51 35 25C35 19.49 30.51 15 25 15ZM25 33C20.59 33 17 29.41 17 25C17 20.59 20.59 17 25 17C29.41 17 33 20.59 33 25C33 29.41 29.41 33 25 33ZM24 28.6H26V30.4H24V28.6ZM24 19.6H26V26.8H24V19.6Z" fill="#DE1B1B" />
    </svg>

);

export const Achieved = ( { className, ...props } ) => (
    <svg width="50" height="50" className={ className } { ...props } viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="50" height="50" rx="25" fill="#9CEFBD" />
        <rect x="5" y="5" width="40" height="40" rx="20" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M34.7372 18.7372L22.0094 31.465L16.3423 25.7865L17.7579 24.3737L22.0108 28.6352L33.323 17.323L34.7372 18.7372Z" fill="#038537" />
    </svg>


);

export const AchievedRepeatable = ( { className, ...props } ) => (
    <svg width="50" height="50" className={ className } { ...props } viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="50" height="50" rx="25" fill="#9CEFBD" />
        <mask id="mask0_9932_42" style={ { maskType: 'alpha' } } maskUnits="userSpaceOnUse" x="0" y="0" width="25" height="50">
            <rect x="25" y="50" width="25" height="50" transform="rotate(-180 25 50)" fill="#D9D9D9" />
        </mask>
        <g mask="url(#mask0_9932_42)">
            <g filter="url(#filter0_d_9932_42)">
                <rect x="50" y="50" width="50" height="50" rx="25" transform="rotate(-180 50 50)" fill="#E1FAEB" />
                <rect x="47.5" y="47.5" width="45" height="45" rx="22.5" transform="rotate(-180 47.5 47.5)" stroke="#1EC963" strokeWidth="5" strokeDasharray="6 6" />
            </g>
        </g>
        <rect x="5" y="5" width="40" height="40" rx="20" fill="white" />
        <rect x="5" y="5" width="40" height="40" rx="20" fill="white" />
        <rect x="5" y="5" width="40" height="40" rx="20" fill="white" />
        <path fillRule="evenodd" clipRule="evenodd" d="M34.7372 18.7372L22.0094 31.465L16.3423 25.7865L17.7579 24.3737L22.0108 28.6352L33.323 17.323L34.7372 18.7372Z" fill="#038537" />
        <defs>
            <filter id="filter0_d_9932_42" x="-30" y="-20" width="110" height="110" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity="0" result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy="10" />
                <feGaussianBlur stdDeviation="15" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.025 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_9932_42" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_9932_42" result="shape" />
            </filter>
        </defs>
    </svg>



);
export const InProgress = ( { className, ...props } ) => (
    <svg width="50" height="50" className={ className } { ...props } viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="50" height="50" rx="25" fill="#A6C8FF" />
        <rect x="5" y="5" width="40" height="40" rx="20" fill="white" />
        <rect x="10" y="10" width="30" height="30" rx="15" fill="#5B9AFF" />
        <rect x="15" y="15" width="20" height="20" rx="10" fill="white" />
        <rect x="20" y="20" width="10" height="10" rx="5" fill="#3272D9" />
    </svg>

);
export const NotAchieved = ( { className, ...props } ) => (
    <svg width="50" height="50" className={ className } { ...props } viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="50" height="50" rx="25" fill="#FAB6B6" />
        <rect x="5" y="5" width="40" height="40" rx="20" fill="white" />
        <path d="M29.85 31L25 26.15L20.15 31L19 29.85L23.85 25L19 20.15L20.15 19L25 23.85L29.85 19L31 20.15L26.15 25L31 29.85L29.85 31Z" fill="#DE1B1B" />
    </svg>


);
export const NotStarted = ( { className, ...props } ) => (
    <svg width="50" height="50" className={ className } { ...props } viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="50" height="50" rx="25" fill="#C9D1DF" />
        <rect x="5" y="5" width="40" height="40" rx="20" fill="white" />
        <path d="M20 24H30V26H20V24Z" fill="#59606D" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15 25C15 19.49 19.49 15 25 15C30.51 15 35 19.49 35 25C35 30.51 30.51 35 25 35C19.49 35 15 30.51 15 25ZM17 25C17 29.41 20.59 33 25 33C29.41 33 33 29.41 33 25C33 20.59 29.41 17 25 17C20.59 17 17 20.59 17 25Z" fill="#59606D" />
    </svg>


);