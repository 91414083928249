import React from 'react';
import { Menu as Mnu } from 'antd';


export const Menu = ( { ...props } ) => {
    return (
        <Mnu
            { ...props }>

        </Mnu>
    );
};
