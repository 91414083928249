import React from 'react';
import { Breadcrumb as Bcrumb } from 'antd';


export const Breadcrumb = ( { ...props } ) => {
    return (
        <Bcrumb
            { ...props }>

        </Bcrumb>
    );
};
