import { AutoPlayController } from '@nackle/arcade-core';
import { Menu } from '@nackle/paper';
import React, { Component } from 'react';
import './MainNavbar.less';
class MainNavbar extends Component {
    public render() {
        return (
            <Menu className="main-navbar" mode="horizontal">
                    <div className="main-navbar-content">
                        <Menu.Item>
                                <AutoPlayController className="auto-play-container" />
                                {/* The "Play History" link will go here */}
                        </Menu.Item>
                    </div>
            </Menu>
        );
    }
}

export default MainNavbar;
