// Testing Environments, Server Side Environments, and Older Browsers may not have window or storage objects.
// This wrapper just consolidates that checking and provides an in memoery fallback

export const STORAGE_TYPE_LOCAL = 'LOCAL';
export const STORAGE_TYPE_SESSION = 'SESSION';
export const STORAGE_TYPE_MEMORY = 'MEMORY';

function Storage ( storageType ) {
    let memoryStorage = {};

    // get the storage based on the type passed in; default to memoryStorage
    let storage = memoryStorage;
    if ( storageType === STORAGE_TYPE_LOCAL && window ) {
        storage = window.localStorage;
    } else if ( storageType === STORAGE_TYPE_SESSION && window ) {
        storage = window.sessionStorage;
    }

    function getItem( key ) {
        if ( storage && storage.getItem ) {
            return storage.getItem( key );
        } else {
            return memoryStorage[ key ];
        }
    }

    function setItem( key, value ) {
        if ( storage && storage.setItem ) {
            storage.setItem( key, value );
        } else {
            memoryStorage[ key ] = value;
        }
    }

    function removeItem( key ) {
        if ( storage && storage.removeItem ) {
            storage.removeItem( key );
        } else {
            delete memoryStorage[ key ];
        }
    }

    return {
        getItem,
        setItem,
        removeItem
    };
}

export default Storage;
