import memoize from 'lodash/memoize';
import parseDate from 'date-fns/parse';
import { resolveLocaleCode } from '../utils/resolve-locale-code';

const formatterOpts = {
    hour: '2-digit',
    minute: '2-digit'
};

const getFormatter = memoize( code => {

    try {
        if ( Intl.DateTimeFormat.supportedLocalesOf( [ code ] ).length ) {
            return Intl.DateTimeFormat( code, formatterOpts );
        }
        else {
            return Intl.DateTimeFormat( 'en-US', formatterOpts );
        }
    } catch ( error ) {
        return Intl.DateTimeFormat( 'en-US', formatterOpts );
    }
} );

export default function formatTime( locale, value ) {
    const code = resolveLocaleCode( locale );

    const dateTime = value instanceof Date ? value : parseDate( value );
    const formatter = getFormatter( code );
    return formatter.format( dateTime );

}
