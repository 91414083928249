// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.circle-picker {
  border-radius: 24px;
  box-shadow: 0px 4px 16px 0px rgba(52, 56, 63, 0.16);
  margin-top: 20px;
  background-color: white;
  padding: 20px;
}
.circle-picker span {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 42px !important;
  height: 42px !important;
}
.circle-picker span div {
  width: 42px !important;
  height: 42px !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.circle-picker span div span {
  height: 28px;
  width: 28px;
  padding: 7px;
}
.circle-picker span div span div {
  height: 28px !important;
  width: 28px !important;
}
`, "",{"version":3,"sources":["webpack://./src/exports/color-picker/color-swatches-picker.less"],"names":[],"mappings":"AAAA;EACI,mBAAA;EACA,mDAAA;EACA,gBAAA;EACA,uBAAA;EACA,aAAA;AACJ;AAEA;EACI,aAAA;EACA,uBAAA;EACA,mBAAA;EACA,sBAAA;EACA,uBAAA;AAAJ;AALA;EAQQ,sBAAA;EACA,uBAAA;EACA,aAAA;EACA,uBAAA;EACA,mBAAA;AAAR;AAZA;EAeY,YAAA;EACA,WAAA;EACA,YAAA;AAAZ;AAjBA;EAoBgB,uBAAA;EACA,sBAAA;AAAhB","sourcesContent":[".circle-picker {\n    border-radius: 24px;\n    box-shadow: 0px 4px 16px 0px rgba(52, 56, 63, 0.16);\n    margin-top: 20px;\n    background-color: white;\n    padding: 20px;\n}\n\n.circle-picker span {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    width: 42px !important;\n    height: 42px !important;\n\n    div {\n        width: 42px !important;\n        height: 42px !important;\n        display: flex;\n        justify-content: center;\n        align-items: center;\n\n        span {\n            height: 28px;\n            width: 28px;\n            padding: 7px;\n\n            div {\n                height: 28px !important;\n                width: 28px !important;\n            }\n\n        }\n\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
