import React from 'react';
import PropTypes from 'prop-types';
import { List, Skeleton } from 'antd';
import InfiniteScroll from 'react-infinite-scroll-component';
import { MoreDotsVert } from '@nackle/origami-icons';
import ReactTimeAgo from 'react-time-ago';

import TimeAgo from 'javascript-time-ago';
import en from 'javascript-time-ago/locale/en';
import fr from 'javascript-time-ago/locale/fr';
import frCA from 'javascript-time-ago/locale/fr-CA';
import de from 'javascript-time-ago/locale/de';
import enGB from 'javascript-time-ago/locale/en-GB';
import it from 'javascript-time-ago/locale/it';
import ja from 'javascript-time-ago/locale/ja';
import ko from 'javascript-time-ago/locale/ko';
import nl from 'javascript-time-ago/locale/nl';
import pl from 'javascript-time-ago/locale/pl';
import pt from 'javascript-time-ago/locale/pt';
import ru from 'javascript-time-ago/locale/ru';
import tk from 'javascript-time-ago/locale/tk';
import zh from 'javascript-time-ago/locale/zh';
import zhHant from 'javascript-time-ago/locale/zh-Hant';
import es from 'javascript-time-ago/locale/es';
import esMX from 'javascript-time-ago/locale/es-MX';
import th from 'javascript-time-ago/locale/th';
import vi from 'javascript-time-ago/locale/vi';

TimeAgo.addLocale( fr );
TimeAgo.addLocale( frCA );
TimeAgo.addLocale( de );
TimeAgo.addLocale( enGB );
TimeAgo.addLocale( it );
TimeAgo.addLocale( ja );
TimeAgo.addLocale( ko );
TimeAgo.addLocale( nl );
TimeAgo.addLocale( pl );
TimeAgo.addLocale( pt );
TimeAgo.addLocale( ru );
TimeAgo.addLocale( tk );
TimeAgo.addLocale( zh );
TimeAgo.addLocale( zhHant );
TimeAgo.addLocale( es );
TimeAgo.addLocale( esMX );
TimeAgo.addLocale( th );
TimeAgo.addLocale( vi );

if ( TimeAgo.getDefaultLocale() !== 'en' ) {
    TimeAgo.addDefaultLocale( en );
} else {
    TimeAgo.addLocale( en );
}



const NotificationListComponent = ( { hasMore, dataSource, split, date, locale, next, ...props } ) => {

    return (
        <div id='scrollableDiv' >
            { dataSource && dataSource.length > 0 &&
                <InfiniteScroll
                    dataLength={ dataSource.length }
                    hasMore={ hasMore !== undefined ? hasMore : false }
                    loader={ <Skeleton paragraph={ { rows: 1 } } active /> }
                    scrollableTarget='scrollableDiv'
                    next={ next } >
                    <List
                        className='notifications-list'
                        split={ split !== undefined ? split : false }
                        dataSource={ dataSource }
                        renderItem={ item => (
                            <div>
                                <List.Item key={ item.id } className={ item.status === 'READ' ? 'inactive' : '' }>
                                    <div dangerouslySetInnerHTML={ { __html: item.content } }></div>
                                    <List.Item.Meta
                                        description={ <ReactTimeAgo date={ new Date( date || item.timestamp ) } locale={ locale === 'zh-TW' ? 'zh-Hant' : locale } /> } />
                                </List.Item>
                                <MoreDotsVert />
                            </div>
                        ) }
                    />
                </InfiniteScroll>
            }
        </div>

    );
};
NotificationListComponent.propTypes = {
    /** should component call next function */
    hasMore: PropTypes.bool,
    /** array of list item data */
    dataSource: PropTypes.array,
    /** render line between items */
    split: PropTypes.bool,
    /** date for time ago */
    date: PropTypes.string,
    /** language locale */
    locale: PropTypes.locale,
    /** a function which must be called after reaching the bottom of the list */
    next: PropTypes.func
};
export { NotificationListComponent };