import countBy from 'lodash/countBy';
import { Loadable, Token } from '../..';
import { mapData } from '../../util/Loadable';
import { TokensAppState, TokenState } from './types';

export const getTokens = (state: TokensAppState): Loadable<Token[]> => state.tokens.tokenList;

export const getAvailableTokens = (state: TokensAppState): Loadable<Token[]> =>
    mapData(getTokens(state), (tokenList) =>
        tokenList.filter((token) =>
            token.state === TokenState.AVAILABLE,
        ),
    );

export const getAvailableTokensForRoom = (state: TokensAppState, roomId: string): Loadable<Token[]> =>
    mapData(getTokens(state), (tokenList) =>
        tokenList.filter((token) =>
            token.state === TokenState.AVAILABLE && token.roomId === roomId,
        ),
    );

export const countAvailableTokensForRoom = (state: TokensAppState, roomId: string): Loadable<number> =>
    mapData(getTokens(state), (tokenList) =>
        countBy(tokenList, { state: TokenState.AVAILABLE, roomId }).true,
    );
