import React from 'react';

export default ( { className, fill, ...props } ) => (
    <svg width="24" height="24" className={ className } { ...props } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 10.33C11.0778 10.33 10.3301 11.0777 10.3301 12C10.3301 12.9223 11.0778 13.67 12.0001 13.67C12.9224 13.67 13.6701 12.9223 13.6701 12C13.6701 11.0777 12.9224 10.33 12.0001 10.33ZM8.33007 12C8.33007 9.97313 9.97319 8.33001 12.0001 8.33001C14.027 8.33001 15.6701 9.97313 15.6701 12C15.6701 14.0269 14.027 15.67 12.0001 15.67C9.97319 15.67 8.33007 14.0269 8.33007 12Z" fill={ fill || 'currentColor' } />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 2.0058L15.5672 5.57291L14.153 6.98712L12.0001 4.83423L9.84718 6.98712L8.43297 5.57291L12.0001 2.0058Z" fill={ fill || 'currentColor' } />
        <path fillRule="evenodd" clipRule="evenodd" d="M19.1659 12L17.013 9.84712L18.4272 8.43291L21.9943 12L18.4272 15.5671L17.013 14.1529L19.1659 12Z" fill={ fill || 'currentColor' } />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.0001 19.1658L9.84718 17.0129L8.43297 18.4271L12.0001 21.9942L15.5672 18.4271L14.153 17.0129L12.0001 19.1658Z" fill={ fill || 'currentColor' } />
        <path fillRule="evenodd" clipRule="evenodd" d="M4.83429 12L6.98718 9.84712L5.57297 8.43291L2.00586 12L5.57297 15.5671L6.98718 14.1529L4.83429 12Z" fill={ fill || 'currentColor' } />
    </svg>
);
