import React from 'react';
import PropTypes from 'prop-types';
import { Popover as Pover, Divider } from 'antd';

const PopoverShell = ( { children, ...props } ) => {
    const { trigger, headerContent, mainContent, title, placement, className } = props;
    const nackleContent = (
        <div>
            <div className={ className ? `popover-content ${ className }` : 'popover-content' }>{ headerContent }</div>
            <Divider />
            <div className='popover-shell-container' >
                { /* Could be react component */ }
                { mainContent }
            </div>
        </div>
    );

    return (
        <Pover placement={ placement } title={ title } content={ nackleContent } trigger={ trigger } { ...props }>
            { children }
        </Pover>
    );
};

PopoverShell.propTypes = {
    /** main content of popover */
    mainContent: PropTypes.node,
    /** display trigger for popover */
    trigger: PropTypes.oneOfType( [ PropTypes.array, PropTypes.oneOf( [ 'hover', 'focus', 'click', 'contextMenu' ] ) ] ),
    /** title of popover */
    title: PropTypes.node,
    /** list header content */
    headerContent: PropTypes.node,
    /** placement of popover in relation to trigger */
    placement: PropTypes.oneOf( [ 'top', 'left', 'right', 'bottom', 'topLeft', 'topRight', 'bottomLeft', 'bottomRight', 'leftTop', 'leftBottom', 'rightTop', 'rightBottom' ] ),
    /** class name */
    className: PropTypes.string
};
export { PopoverShell };
