import React from 'react';

export default ( { className, fill, ...props } ) => (
    <svg width="24" height="24" className={ className } { ...props } viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5.66005 16.86L3.92005 17.87C4.54005 18.72 5.28005 19.46 6.13005 20.08L7.14005 18.34C6.58005 17.91 6.08005 17.41 5.66005 16.86Z" fill={ fill || 'currentColor' } />
        <path d="M4.07005 13H2.05005C2.16005 14.11 2.46005 15.17 2.91005 16.14L4.64005 15.13C4.35005 14.46 4.16005 13.74 4.07005 13Z" fill={ fill || 'currentColor' } />
        <path d="M4.64005 8.87L2.91005 7.86C2.46005 8.84 2.16005 9.89 2.05005 11H4.07005C4.16005 10.25 4.36005 9.53 4.64005 8.87Z" fill={ fill || 'currentColor' } />
        <path d="M7.86005 21.09C8.84005 21.54 9.89005 21.83 11 21.95V19.94C10.25 19.85 9.53005 19.65 8.87005 19.37L7.86005 21.1V21.09Z" fill={ fill || 'currentColor' } />
        <path d="M12 2C9.75005 2 7.69005 2.76 6.02005 4.02L4.25005 2.25V7.25H9.25005L7.44005 5.44C8.73005 4.53 10.3 4 12 4C16.41 4 20 7.59 20 12C20 16.07 16.94 19.44 13 19.93V21.95C18.04 21.45 22 17.17 22 12C22 6.49 17.51 2 12 2Z" fill={ fill || 'currentColor' } />
        <path d="M13.02 5.99H11.02V13.04L15.34 16.75L16.64 15.23L13.01 12.12H13.02V5.99Z" fill={ fill || 'currentColor' } />
    </svg>

);