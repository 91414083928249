import difference from 'lodash/difference';
import isEqual from 'lodash/isEqual';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { AppState, FALLBACK_LOCALE, Games, Session, Translations } from '../../dux';
import { dataOrElse, Loadable } from '../../util/Loadable';
import { ReduxProps } from '../../util/reactReduxExt';

interface Props {
    projectKeys: string[];
}

const mapStateToProps = (state: AppState) => ({
    locale: Session.getLocale(state),
});

const mapDispatchToProps = {
    loadTranslations: Translations.loadTranslations,
    loadArcadesAndTheirData: Games.loadArcadesAndTheirData,
};

type CombinedProps = ReduxProps<Props, typeof mapStateToProps, typeof mapDispatchToProps>;

/**
 * Handles the logic of ensuring that translations for the user's current locale have been loaded
 */
class TranslationLoader extends Component<CombinedProps> {

    constructor(props: CombinedProps) {
        super(props);

        this.loadTranslations(props.locale, props.projectKeys);
    }

    public UNSAFE_componentWillUpdate(nextProps: Readonly<CombinedProps>): void {
        try {
            if (!isEqual(nextProps.locale, this.props.locale)) {
                this.loadTranslations(nextProps.locale, nextProps.projectKeys);
            } else if (!isEqual(nextProps.projectKeys, this.props.projectKeys)) {
                const newProjectKeys = difference(nextProps.projectKeys, this.props.projectKeys);
                this.loadTranslations(nextProps.locale, newProjectKeys);
            }
        } catch (e) {
            console.error(e);
        }
    }

    public render() {
        return <></>;
    }

    private loadTranslations(locale: Loadable<string>, projectKeys: string[]) {
        const localeString = dataOrElse(locale, () => {
            console.warn(`User's locale not set. Falling back to ${FALLBACK_LOCALE}`);
            return FALLBACK_LOCALE;
        });
        projectKeys.forEach((projectKey) =>
            this.props.loadTranslations(projectKey, localeString),
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TranslationLoader);
