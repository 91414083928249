// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.gameCard-container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  overflow: hidden;
  background: white;
  animation: room-game-entrance 1000ms;
  border-width: 0;
  text-align: inherit;
  transition: transform 200ms;
}
.gameCard-container.nkl-card {
  padding: 0;
  margin-left: 10px;
  margin-right: 10px;
  margin-top: 10px;
  margin-bottom: 10px;
  border-radius: 3px;
}
.gameCard-container:not([disabled]):hover,
.gameCard-container:not([disabled]):focus {
  transform: scale(1.05);
}
.gameCard-container .game-cover-image-container {
  position: relative;
  width: 100%;
  height: 160px;
}
.gameCard-container .game-cover-image-container .game-cover-image-spinner {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.gameCard-container .game-cover-image-container .game-cover-image {
  width: 100%;
  height: 100%;
  background-position: center;
  background-size: cover;
  animation: cabinet-image-entrance 500ms;
}
.gameCard-container .gameCard-footer {
  background: white;
  padding-left: 10px;
  padding-right: 10px;
  padding-bottom: 10px;
  padding-top: 10px;
  display: flex;
  align-items: baseline;
}
.gameCard-container[disabled] {
  cursor: not-allowed;
  color: #757575;
}
.gameCard-container[disabled] .tokensIcon {
  opacity: 0.5;
}
.gameCard-container[disabled] .game-cover-image {
  opacity: 0.5;
}
.gameName-container {
  flex: 1 1 0;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.tokensToPlay-container {
  display: none;
  flex: 0 0 auto;
  padding-left: 1ch;
  vertical-align: middle;
  font-size: 14px;
}
.tokensIcon {
  width: 0.75em;
  height: 0.75em;
}
@keyframes room-game-entrance {
  from {
    opacity: 0;
    transform: scale(1.1, 1.1);
  }
  to {
    opacity: 1;
    transform: scale(1, 1);
  }
}
@keyframes cabinet-image-entrance {
  from {
    transform: translate(0, -100%);
  }
  to {
    transform: translate(0, 0%);
  }
}
/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
`, ""]);
// Exports
export default ___CSS_LOADER_EXPORT___;
