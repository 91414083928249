import { resolveLocaleCode } from '../utils/resolve-locale-code';

export default function formatNumber(
    locale,
    formatOptions,
    amount
) {
    if ( typeof amount === 'number' && isNaN( amount ) ) {
        return '';
    }

    let code = resolveLocaleCode( locale );

    try {
        Intl.NumberFormat.supportedLocalesOf( [ code ] );
    } catch ( error ) {
        code = 'en-US';
    }

    const formatter = new Intl.NumberFormat( code, formatOptions );
    return formatter.format( Number( amount ) );
}
