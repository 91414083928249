import React from 'react';
import { Input as AntInput } from 'antd';
import { Eye, EyeClosed } from '@nackle/origami-icons';


const Password = ( { ...props } ) => {
    const passwordIcon = ( visible ) => (
        <span
            role="img"
            aria-label="eye-invisible"
            tabIndex="-1"
        >
            { visible ? <EyeClosed /> : <Eye /> }
        </span>
    );

    return (
        <AntInput.Password
            iconRender={ passwordIcon }
            { ...props }
        />
    );
};

export default Password;
