import { AxiosInstance } from 'axios';
import {
    ArcadesView,
    CabinetsView,
    CompletePlayGameRequest,
    PlayGameRequest,
    PlayGameView,
    RoomsView,
    TokensView,
} from './types';

export const ARCADE_ROOT = '/arcadeapi/services/v1'; // visible for testing

export const listArcades = async (client: AxiosInstance) =>
    (await client.get<ArcadesView>(`${ARCADE_ROOT}/arcades`)).data;

export const listArcadeCabinets = async (client: AxiosInstance, arcadeId: string) =>
    (await client.get<CabinetsView>(`${ARCADE_ROOT}/arcades/${arcadeId}/cabinets`)).data;

export const listArcadeRooms = async (client: AxiosInstance, arcadeId: string) =>
    (await client.get<RoomsView>(`${ARCADE_ROOT}/arcades/${arcadeId}/rooms`)).data;

export const getTokens = async (client: AxiosInstance, personId: string) =>
    (await client.get<TokensView>(`${ARCADE_ROOT}/tokens/${personId}`)).data;

export const playGame = async (
    client: AxiosInstance,
    {
        cabinetId,
        score = null,
        personId,
        tokenIds,
    }: {
        cabinetId: string,
        score?: number | null,
        personId: string,
        tokenIds: string[],
    },
): Promise<PlayGameView> => {
    const payload: PlayGameRequest = { score, personId, tokens: tokenIds, cabinetId };
    return (await client.post<PlayGameView>(`${ARCADE_ROOT}/plays`, payload)).data;
};

export const playGameComplete = async (
    client: AxiosInstance,
    {
        cabinetHistoryId,
        isComplete,
        score = null,
    }: {
        cabinetHistoryId: string,
        isComplete: boolean;
        score?: number | null;
    },
): Promise<undefined | null> => {
    const payload: CompletePlayGameRequest = { isComplete, score };
    return (await client.post(`${ARCADE_ROOT}/plays/${cabinetHistoryId}/update`, payload)).data;
};

export const loadPreferences = async (
    client: AxiosInstance,
) => {
    throw new Error('TODO');
};

export const updateAutoPlayPreference = async (
    client: AxiosInstance,
    setting: boolean,
) => {
    throw new Error('TODO');
};
