import identity from 'lodash/identity';
import React, { Fragment, ReactElement } from 'react';
import { connect } from 'react-redux';
import { Translations, TranslationsAppState } from '../../dux/translations';
import { ReduxProps } from '../../util/reactReduxExt';
import DOMPurify from 'dompurify';

/**
 * Basic string formatter function using Java template string-esque syntax
 * (This is compatible with the intl-tools library, which is not being used here because it's not quite useful enough)
 */
const format = (template: string, args: { [key: string]: any }): string =>
    template.replace(/\${([^}]+?)}/g, (match, key) => {
        if (key in args) {
            return String(args[key]);
        }
        return match;
    });

/**
 * Figure out what string to render
 */
function resolveTranslation(translation: string | undefined, args: { [key: string]: any } | undefined, fallback: string) {
    // Loaded: Show data.
    if (typeof translation === 'string') {
        if (args) {
            // ONLY try to format the original template string, not the "loading" string or the fallback.
            return format(translation, args);
        }
        return translation;
    }

    // Translation failed or is loading. Show untranslated key as a fallback
    return fallback;
}

interface Props {
    id: string;

    // Optional render-prop syntax if something needs a string instead of JSX
    children?: (translated: string) => string | ReactElement;

    fallback?: string;

    args?: { [key: string]: any };

    stripHtml?: boolean;

    parseHtml?: boolean;
}

const mapStateToProps = (state: TranslationsAppState, { id }: Props) => ({
    translation: Translations.getTranslation(state, id),
});

/**
 * Retrieves translated strings from CMX
 * 
 * TODO: Use intl-tools instead now that intl-tools has been updated with features we can use.
 *
 * @param args - Optional arguments for the formatted string
 * @param children - Optional render-prop argument for passing the translated string in in situations where JSX tags
 *      are not appropriate. If supplied, its result will be rendered instead of the translated string.
 * @param fallback - (Default = id) String to display if the translated string can't be found.
 * @param id - Key to find the translated string in CMX
 * @param parseHtml - Whether to parse HTML in the translated string (default false)
 * @param stripHtml - Whether to remove HTML tags from the translated string (default false)
 * @param translation - (Internally supplied by Redux) The translated string
 */
const Translate = ({ args, children = identity, fallback, id, parseHtml, stripHtml, translation }: ReduxProps<Props, typeof mapStateToProps>) => {
    let translated = resolveTranslation(translation, args, fallback === undefined ? id : fallback);

    // Convert translated content to HTML and return if needed.
    if (parseHtml) {
        return <div dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize( translated ) }} />;
    }

    // Remove embedded HTML from the translated string if needed.
    if (stripHtml) {
        const HTML_TAG_REGEX = /<(?:.|\n)*?>/gm;
        translated = translated.replace(HTML_TAG_REGEX, '');
    }

    return (
        <Fragment>
            {(typeof children === 'function') ? children(translated) : translated}
        </Fragment>
    );
};

const decorated = connect(mapStateToProps)(Translate);
export { decorated as Translate };
