import React from 'react';
import { AppState, ReduxProps, Session } from '@nackle/arcade-core';
import { Bridge } from '@nackle/bridge';
import { connect } from 'react-redux';
import { auth } from '../config';

export interface Props {
    children?: any;
}

const mapStateToProps = (state: AppState) => {
    return {
        isAuthenticated: Session.isAuthenticated(state),
    };
};

const mapDispatchToProps = {
    setUserData: Session.setUserData,
};

export class AppAuth extends React.Component<ReduxProps<Props, typeof mapStateToProps, typeof mapDispatchToProps>> {
    public render() {
        const { children, isAuthenticated } = this.props;
        return (
            <Bridge
                requiresAuth
                auth={auth}
                onSignIn={this.handleUserDataChanged}
                onSignInError={this.onError}
                onAuthStateChange={this.handleUserDataChanged}
                onSignOut={() => {this.handleUserDataChanged( undefined );}}
                onSignOutError={this.onError}
            >
                {isAuthenticated && children}
            </Bridge>
        );
    }

    private onError = (error: any) => console.error(error);

    private handleUserDataChanged = (userData: any) => {
        this.props.setUserData(userData);
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(AppAuth);
