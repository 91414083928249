/*
Type-safe reducer and dispatcher library

Usage example:

// reducer.ts:
interface FooState {
    bar: string;
}

export const fooReducer = createReducer({
    // initial state
    bar: 'some value',
} as FooState, {
    // Reducers
    FOO_SET_BAR: (state: FooState, newBar: string) => ({
        ...state,
        bar: newBar,
    }),
});

// actions.ts:
function setBar(newBar: string) {
    // Annotating with DispatchFor<fooReducer> ensures that dispatch() can only accept valid actions
    // for fooReducer.
    return (dispatch: DispatchFor<fooReducer>) => {
        dispatch({
            type: 'FOO_SET_BAR',        // Typescript checks that this is present in fooReducer's reducer map.
            payload: newBar,            // Typescript checks that this matches FOO_SET_BAR's 2nd parameter type.
        });
    };
}
*/

import { Action, AnyAction, Reducer as ReduxReducer } from 'redux';

/**
 * Subtype of Action where metadata is stored in its "payload" field.
 * The payload is passed as the second parameter to a ReducerMap function.
 */
export interface PayloadAction<ActionName, PayloadType> extends Action<ActionName> {
    payload: PayloadType;
}

/**
 * Schema for a reducer map.
 *
 * This type allows createReducer() to assert the generic format of a reducer map, but it isn't used much after that
 * because doing so would strip out important metadata about exactly which Action types are supported.
 */
interface ReducerMap<StateType> {
    // Each function in a reducer map takes the current state as a first parameter and a PayloadAction's `payload` field
    // (if desired) as the second parameter. If there's no second parameter, the action does not need a `payload`.
    [key: string]: (state: StateType, payload: any) => StateType;
}

/**
 * Defines an action which corresponds to keys on a reducer map.
 */
type ReducerMapAction<StateType, ReducerMapType, ActionName extends keyof ReducerMapType> =
    // Parameter-less reducers don't expect a `payload` property on the action.
    // For some reason we need to match on this BEFORE with-payload handlers;
    // otherwise, Typescript will think ALL events require payloads.
    ReducerMapType[ActionName] extends (state: StateType) => StateType ?
        Action<ActionName> :

    // Reducers with a second parameter require `payload`.
    ReducerMapType[ActionName] extends (state: StateType, payload: infer PayloadType) => StateType ?
        PayloadAction<ActionName, PayloadType> :

        // Fun fact: You can abuse string constants to add error messages to conditional-type failures
        'UNABLE TO INFER ACTION TYPE - Did you declare a state on createReducer()\'s defaultState?';

/**
 * Reducer subtype type which includes metadata about which kinds of actions it can accept.
 *
 * This also augments the Reducer to include its default state as a key (I'm not sure why the combiner wants it TBH
 * but it was already there)
 */
// tslint:disable-next-line:no-unused - ReducerMapType is required for DispatchFor<> to properly capture through pattern-matching
export interface Reducer<StateType, ReducerMapType> extends ReduxReducer {
    (state: StateType, action: AnyAction): StateType;

    defaultState: StateType;
}

/**
 * Type macro (replacing Redux's `Dispatch` type) enforcing that a dispatch() function can only accept actions supported
 * by a given reducer
 */
export type DispatchFor<R extends Reducer<any, any>> =
    // Extract the state type and reducer map schema embedded in the reducer's type
    R extends Reducer<infer StateType, infer ReducerMapType> ?

        // dispatch() accepts a single parameter - an action
        <ActionName extends keyof ReducerMapType>(
            action: ReducerMapAction<StateType, ReducerMapType, ActionName>,
        ) => ReducerMapAction<StateType, ReducerMapType, ActionName> :

        'UNABLE TO INFER TYPE OF DISPATCH FUNCTION FROM REDUCER';

/**
 * Build a reducer using a default state and an object mapping action types to handler functions.
 *
 * !!! Type inference note !!!
 *
 * You MUST either declare that set the defaultState to be StateType, or pass both StateType and ReducerMapType (via
 * `typeof reducers`) as generic parameters. If you try to use partial type inference (`ReducerMapType = any`),
 * Typescript treats it as `any` and loses track of its layout, preventing it from validating action types.
 *
 * In other words, the exact layout of ReducerMapType (its exact keys and values) must be preserved by the type system
 * at all times.
 *
 * @template StateType - Inferred from defaultState's type.
 * @template ReducerMapType - Inferred from the reducer's object.
 *
 * @param defaultState - The starting state. **Please explicitly declare its type**
 * @param reducerMap - Each method of this function will be invoked with the initial state
 */
export function createReducer<StateType, ReducerMapType extends ReducerMap<StateType>>(
    defaultState: StateType,
    reducerMap: ReducerMapType,
): Reducer<StateType, ReducerMapType> {

    function reducer(state: StateType = defaultState, action: AnyAction) {
        let nextState: StateType = state;

        if (action.type in reducerMap) {
            const reducerFunction = reducerMap[action.type];
            nextState = reducerFunction(state, action.payload);

            // Typescript ought to prevent this from happening, but in projects without Typescript it helps catch weird
            // bugs. There's little harm in including it here too.
            if (!nextState) {
                console.warn(`Reducer for ${action.type} failed to return usable value (${nextState}). ` +
                    'Did you miss a return statement?');
            }

            // console.log(action, nextState);
        }

        return nextState;
    }

    // Augment with the default state, because
    reducer.defaultState = defaultState;

    return reducer;
}
