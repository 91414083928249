import { AxiosInstance } from 'axios';

export const COMPANY_SETUP_ROOT = `/v1/company-setup`;

export const getCompanyIdentifiers = async (
    client: AxiosInstance,
    companyId: string,
): Promise<string[]> => {
    const variables = { companyId };
    const query = `
        query companyQuery($companyId: String!) {
            company(id: $companyId) {
                identifierList
            }
        }
    `;

    // This is how the GraphQL response will come back for the above query. I can't get it any flatter than this.
    interface ResponseType {
        data: {
            company: {
                identifierList: string[];
            };
        };
    }

    const response = await client.post<ResponseType>(`${COMPANY_SETUP_ROOT}/graphql`, { variables, query });

    return response.data.data.company.identifierList;
};
