import React, { Fragment, forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Input as AntInput } from 'antd';
import { Close } from '@nackle/origami-icons';
import Password from './password';
import Search from './search';


const Input = forwardRef( ( props, ref ) => {
    const { allowClear, status, statusIcon, suffix, ...restProps } = props;

    const statusSuffix = (
        <Fragment>
            { suffix }
            { statusIcon }
        </Fragment>
    );

    return (
        <AntInput
            ref={ ref }
            status={ status }
            suffix={ status ? statusSuffix : suffix }
            allowClear={ allowClear ? { clearIcon: <Close /> } : false }
            { ...restProps }
        />
    );
} );

const TextArea = ( { ...props } ) => (
    <AntInput.TextArea { ...props } />
);
Input.propTypes = {
    /** show clear icon */
    allowClear: PropTypes.bool,
    /** optional status */
    status: PropTypes.oneOf( [ 'error', 'warning' ] ),
    /** optional status icon */
    statusIcon: PropTypes.node,
    /** icon to the right of input */
    suffix: PropTypes.node
};
Input.Password = Password;
Input.Search = Search;

Input.TextArea = TextArea;

export { Input };