import { createReducer, DispatchFor } from '../../util/createReducer';
import { errorLoading, loaded, loading, notLoaded } from '../../util/Loadable';
import { PreferencesState } from './types';

const initialState: PreferencesState = {
    autoPlay: notLoaded(),
};

export const preferences = createReducer(initialState, {
    PREFERENCES_LOADING: (state: PreferencesState) => ({
        ...state,
        autoPlay: loading(state.autoPlay),
    }),
    PREFERENCES_LOADING_ERROR: (state: PreferencesState, error: any) => ({
        ...state,
        autoPlay: errorLoading(error, state.autoPlay),
    }),

    PREFERENCES_AUTO_PLAY_LOADED: (state: PreferencesState, setting: boolean) => ({
        ...state,
        autoPlay: loaded(setting),
    }),
    PREFERENCES_AUTO_PLAY_UPDATING: (state: PreferencesState) => ({
        ...state,
        autoPlay: loading(state.autoPlay),
    }),
    PREFERENCES_AUTO_PLAY_UPDATE_ERROR: (state: PreferencesState, error: any) => ({
        ...state,
        autoPlay: errorLoading(error, state.autoPlay),
    }),
    PREFERENCES_AUTO_PLAY_UPDATED: (state: PreferencesState, setting: boolean) => ({
        ...state,
        autoPlay: loaded(setting),
    }),
});

export type PlayDispatch = DispatchFor<typeof preferences>;
