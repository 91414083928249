import React from 'react';
import PropTypes from 'prop-types';
import { Skeleton as AntdSkeleton } from 'antd';

const Skeleton = ( { children, ...props } ) => {
    // make active the default
    const active = props.active === undefined || props.active;
    return (
        <AntdSkeleton { ...props } active={ active }>
            { children }
        </AntdSkeleton>
    );
};
Skeleton.propTypes = {
    /** active or static skeleton display */
    active: PropTypes.bool
};
Skeleton.defaultProps = {
    active: true
};
export { Skeleton };