import isEqual from 'lodash/isEqual';
import Event from './utils/event';
import './utils/string-template2';

let CURRENT_LOCALE_CODE;
let CURRENT_LOCALE_BUNDLE = {};
const languageChangeEvent = new Event();

export async function setLanguageBundle( localeCode, bundle ) {

    CURRENT_LOCALE_CODE = localeCode;
    CURRENT_LOCALE_BUNDLE = bundle;

    languageChangeEvent.fire( { localeCode, bundle } );

    return {
        locale: CURRENT_LOCALE_CODE,
        bundle: CURRENT_LOCALE_BUNDLE
    };
}

export async function updateLanguageBundle( bundlePatch ) {
    CURRENT_LOCALE_BUNDLE = { ...CURRENT_LOCALE_BUNDLE, ...bundlePatch };

    languageChangeEvent.fire( {
        CURRENT_LOCALE_CODE,
        CURRENT_LOCALE_BUNDLE,
        localeCode: CURRENT_LOCALE_CODE,
        bundle: CURRENT_LOCALE_BUNDLE
    } );

    return {
        locale: CURRENT_LOCALE_CODE,
        bundle: CURRENT_LOCALE_BUNDLE
    };
}

export function getString( key, replaceOptions, attributes = false ) { 
    let value;   
    if ( attributes ) {
        for ( const keyName in CURRENT_LOCALE_BUNDLE ) {
            if ( key === keyName  &&  Array.isArray( CURRENT_LOCALE_BUNDLE[ key ] ) ) {
                CURRENT_LOCALE_BUNDLE[ key ].some( key => 
                    isEqual( key.attributes, attributes ) ? value = key.value : null
                );
            }
        }
    } else {
        value = CURRENT_LOCALE_BUNDLE[ key ];
    }

    // do replacement
    if ( typeof value !== 'string' ) {
        return `Missing locale value (${ key })`;
    }

    return replaceOptions ? value.template( replaceOptions ) : value;
}

export function getStringJSXReplaceOptions( key, replaceOptions ) {
    const value = CURRENT_LOCALE_BUNDLE[ key ];

    //do replacement
    if ( typeof value !== 'string' ) {
        return `Missing locale value (${ key })`;
    }
    let returnString = value;

    Object.keys( replaceOptions ).map( ( option ) => {
        returnString = returnString.replace( new RegExp( option, 'g' ), replaceOptions[ option ] );
    } );
    return returnString;
}

export function keyExists( key ) {
    return !!CURRENT_LOCALE_BUNDLE[ key ];
}

export { CURRENT_LOCALE_BUNDLE, CURRENT_LOCALE_CODE, languageChangeEvent };
