import React from 'react';
import PropTypes from 'prop-types';
import { Badge as Bdg } from 'antd';
import classNames from 'classnames';


const Badge = ( { type, size, className, children, ...props } ) => {

    const color = ( type ) => {
        switch ( type ) {
            case 'brand':
                return 'rgb(var(--color-primary-none, 0, 125, 141))';
            case 'info':
                return '#1D5BBF';
            case 'success':
                return '#038537';
            case 'warning':
                return '#FCC419';
            case 'error':
                return '#DE1B1B';
            case 'support':
                return '#7D3CBD';
            case 'neutral':
                return '#FFFFFF';
            default:
                return '#C9D1DF';
        }
    };
    const badgeClasses = classNames( {
        [ className ]: className ? true : false,
        [ type ]: type ? true : false,
        'default': type === 'default' || !type ? true : false,
        [ `badge-size-${ size }` ]: size ? true : false
    } );
    return (
        <Bdg { ...props } color={ color( type ) } className={ badgeClasses }>
            { children }
        </Bdg>
    );
};

Badge.propTypes = {
    /** badge type */
    type: PropTypes.oneOf( [ 'brand', 'info', 'success', 'warning', 'error', 'support', 'default', 'neutral' ] ),
    /** badge size */
    size: PropTypes.oneOf( [ 'small', 'medium', 'large' ] )
};
export { Badge };
