import { CabinetEntryView, CabinetView, GamesAppState } from '../games';
import { Token, Tokens, TokensAppState } from '../tokens';
import { ItemSelector, randomItem } from '../../util/arrays';
import { DispatchFor } from '../../util/createReducer';
import { promotion } from './reducer';
import { PromotedGame, PromotionAppState } from './types';

export type PromotionDispatch = DispatchFor<typeof promotion>;

export const setPromotedCabinet = (cabinet: CabinetView, roomId: string) =>
    async (dispatch: PromotionDispatch, getState: () => PromotionAppState) => {
        const payload: PromotedGame = {
            bannerImage: cabinet.bannerImage,
            cabinetId: cabinet.cabinetId,
            fallbackDescription: cabinet.description,
            fallbackName: cabinet.name,
            romId: cabinet.romId,
            roomId,
        };
        dispatch({ type: 'PROMOTION_SET_CABINET', payload });
    };

export const pickARandomCabinetToBePromoted = (
    {
        // Inject the random-item-picker function so tests are predictable
        selectCabinetEntry = randomItem,
        selectToken = randomItem,
    }: {
        selectCabinetEntry?: ItemSelector<CabinetEntryView>,
        selectToken?: ItemSelector<Token>,
    } = {},
) =>
    async (dispatch: PromotionDispatch, getState: () => PromotionAppState & GamesAppState & TokensAppState) => {
        const state = getState();

        const tokenList = Tokens.getAvailableTokens(state);
        if (!tokenList.isLoaded) {
            throw new Error(`Token list isn't loaded`);
        }

        const tokensWithRooms = tokenList.data
            .filter((token) =>
                hasRoom(state, token.roomId) &&
                getRoom(state, token.roomId).cabinets.some((cabinetEntry) =>
                    hasCabinet(state, cabinetEntry.cabinetId),
                ),
            );

        if (tokensWithRooms.length === 0) {
            dispatch({ type: 'PROMOTION_SET_NOTHING' });
            return;
        }

        const randomToken = selectToken(tokensWithRooms);
        const randomRoom = getRoom(state, randomToken.roomId);
        const randomCabinetEntry = selectCabinetEntry(randomRoom.cabinets.filter((cabinetEntry) => hasCabinet(state, cabinetEntry.cabinetId)));
        const randomCabinet = getCabinet(state, randomCabinetEntry.cabinetId);

        setPromotedCabinet(randomCabinet, randomRoom.id)(dispatch, getState);
    };

function getRoom(state: GamesAppState, roomId: string) {
    return state.games.roomsById[roomId];
}

function hasRoom(state: GamesAppState, roomId: string) {
    return roomId in state.games.roomsById;
}

function getCabinet(state: GamesAppState, cabinetId: string) {
    return state.games.cabinetsById[cabinetId];
}

function hasCabinet(state: GamesAppState, cabinetId: string) {
    return cabinetId in state.games.cabinetsById;
}
